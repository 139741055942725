import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

function EditLink({
    closeModal,
    link,
    editLink,
    extensionIcons,
    validationSchema,
    getFileExtensionFromURL,
    fallbackExtension
}) {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors, isDirty }
    } = useForm({ resolver: yupResolver(validationSchema) });
    const watchURL = watch('URL');
    const watchExtension = watch('extension');

    useEffect(() => {
        if (watchURL) {
            const extensionFromURL = getFileExtensionFromURL(watchURL);
            setValue('extension', extensionFromURL);
        }
    }, [watchURL]);

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('content.edit_URL')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(() => editLink(isDirty, getValues()))} className="container d-flex flex-column">
                    <Form.Group className="my-2">
                        <Form.Label>{t('content.title')}</Form.Label>
                        <Form.Control
                            className="removed-shadow"
                            name="title"
                            placeholder={t('content.title')}
                            {...register('title', { value: link?.title })}
                            error={errors.title?.message}
                        />
                        {errors.title?.message && <Form.Label className="error-placeholder">{errors.title?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label>{t('content.displayURL')}</Form.Label>
                        <InputGroup className="me-1">
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={extensionIcons[watchExtension || fallbackExtension]} className="icon-primary"/>
                            </InputGroup.Text>
                            <Form.Control
                                className="removed-shadow"
                                name="URL"
                                placeholder={t('content.displayURL')}
                                {...register('URL', { value: link?.URL })}
                                error={errors.URL?.message}
                            />
                        </InputGroup>
                        {errors.URL?.message && <Form.Label className="error-placeholder">{errors.URL?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label>{t('content.select_type_of_document')}</Form.Label>
                        <Form.Select
                            className="removed-shadow"
                            isInvalid={errors?.extension}
                            placeholder={t('content.select_type_of_document')}
                            name="extension"
                            defaultValue={link?.extension}
                            {...register('extension', { value: link?.extension })}
                        >
                            {Object.keys(extensionIcons).map((extension, i) => (
                                <option key={i} value={extension}>{extension}</option>
                            ))}
                        </Form.Select>
                        {errors.extension?.message && <Form.Label className="error-placeholder">{errors.extension?.message}</Form.Label>}
                    </Form.Group>
                    <div className="d-flex justify-content-between mt-2">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('administration.save_changes')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditLink;