import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { TOAST_TYPE } from '../constants';

const initialState = {
    isVisible: false,
    message: '',
    type: TOAST_TYPE.ERROR
};

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToastMessage: (state, action) => {
            state.type = action.payload.type || TOAST_TYPE.ERROR;
            state.isVisible = true;
            state.message = action.payload?.message || t('error_message.global_message');
        },
        showToastErrorMessage: (state, action) => {
            state.type = TOAST_TYPE.ERROR;
            state.isVisible = true;
            state.message = action?.payload || t('error_message.global_message');
        },
        showToastSuccessMessage: (state, action) => {
            state.type = TOAST_TYPE.SUCCESS;
            state.isVisible = true;
            state.message = action?.payload || t('success_message.global_message');
        },
        hideToast: (state) => {
            state.isVisible = false;
        }
    }
});

export const { showToastMessage, hideToast, showToastErrorMessage, showToastSuccessMessage } = toastSlice.actions;

export default toastSlice.reducer;
