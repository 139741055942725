export const reloadPage = () => {
    window.location.reload();
};

const addHttpsToUrlIfNeeded = (url) => {
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        return `https://${url}`;
    }
    return url;
};

export const openLinkInNewTab = (url) => {
    window.open(addHttpsToUrlIfNeeded(url), '_blank').focus();
};

export const reformatDate = (timestamp) => {
    if (!timestamp) {
        return '';
    }

    const date = new Date(timestamp);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
};

export const reformatToISODateString = (timestamp) => {
    if (!timestamp) {
        return '';
    }

    return (new Date(timestamp)).toISOString().split('T')[0];
};
