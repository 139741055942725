import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';
import { COLORS } from '../../../../constants';

function EditGoalsAndMetricsBestContribution({ closeModal, bestContribution, editBestContribution, validationSchema }) {
    const { t } = useTranslation();
    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isDirty }
    } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        register('bestContribution', { value: bestContribution?._id });
    }, []);

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('best_contribution.edit_best_contribution')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(async () => editBestContribution(getValues(), isDirty))}
                    className="container d-flex flex-column"
                >
                    <Form.Group className="mb-3">
                        <Form.Label>{t('best_contribution.best_contribution')}</Form.Label>
                        <Form.Control
                            defaultValue={bestContribution.title}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('content.end_goal')}</Form.Label>
                        <Form.Control
                            type="number"
                            className="removed-shadow"
                            name="endGoal"
                            error={errors.endGoal?.message}
                            {...register('endGoal', { value: bestContribution?.endGoal })}
                        />
                        {errors.endGoal?.message && <Form.Label className="error-placeholder">{errors.endGoal?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-2">{t('content.select_graph_color')}</Form.Label>
                        <Form.Control
                            type="color"
                            name="color"
                            {...register('color', { value: bestContribution?.color || COLORS.PRIMARY_COLOR })}
                            error={errors.color?.message}
                        />
                        {errors.color?.message && <Form.Label className="error-placeholder">{errors.color?.message}</Form.Label>}
                    </Form.Group>
                    <div className="d-flex mt-4 justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('administration.save_changes')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditGoalsAndMetricsBestContribution;