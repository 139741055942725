import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { addNewSection, closeModal } from '../../../features/agendaSlice';
import AgendaInfo from './agendaInfo';
import NewSection from './newSection';
import Section from './section';
import Module from './module';
import NewModule from './newModule';
import PermissionWrapper from '../../permissionWrapper';
import { MAX_NUMBER_OF_MODULE_TYPES, ROLES_PERMISSIONS } from '../../../constants';

function Agenda() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const teamData = useSelector((state) => state.agenda?.userData?.lastSelectedTeam);
    const { showAgendaModal, sections, newSection, newModule } = useSelector((state) => state.agenda);
    // there are modules that have maximum number of instances in the agenda
    const [modulesThatCantBeAdded, setModulesThatCantBeAdded] = useState();

    const close = () => {
        dispatch(closeModal());
    };

    useEffect(() => {
        if (sections) {
            const modulesThatCantBeAddedTemp = [];
            const modulesCount = Object.entries(MAX_NUMBER_OF_MODULE_TYPES).reduce((acc, [moduleType, max]) => ({
                ...acc,
                [moduleType]: { maxNumber: max, count: 0 }
            }), {});

            sections?.map((section) => section?.modules?.forEach((module) => {
                if (modulesCount[module.type]) {
                    modulesCount[module.type].count += 1;
                    if (modulesCount[module.type].count >= modulesCount[module.type].maxNumber) {
                        modulesThatCantBeAddedTemp.push(module.type.toString());
                    }
                }
            }));

            setModulesThatCantBeAdded(modulesThatCantBeAddedTemp);
        }
    }, [sections]);

    return (
        <Modal show={showAgendaModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>
                        {t('agenda.agenda_for')}
                    </span>
                    <span className="text-secondary">{teamData?.name}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AgendaInfo/>
                {sections?.map((section, sectionIndex) => (
                    <div className="mb-1 agenda" key={sectionIndex}>
                        <Section sectionIndex={sectionIndex}/>
                        {section?.modules?.map((module, moduleIndex) => (
                            <Module key={moduleIndex} sectionIndex={sectionIndex} moduleIndex={moduleIndex}/>
                        ))}
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                            {newModule[sectionIndex] &&
                                <NewModule
                                    modulesThatCantBeAdded={modulesThatCantBeAdded}
                                    sectionIndex={sectionIndex}
                                />
                            }
                        </PermissionWrapper>
                    </div>
                ))}
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                    {!!Object.keys(newSection).length && <NewSection/>}
                    <h5 className="mt-2 pt-3 cursor visible-icon w-25" onClick={() => dispatch(addNewSection())}>
                        <i className="bi bi-plus-square m-2"/>
                        {t('agenda.add_section')}
                    </h5>
                </PermissionWrapper>
            </Modal.Body>
            <Modal.Footer>
                <Button className="primary-button w-25" onClick={close}>{t('dashboard_shared.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Agenda;

