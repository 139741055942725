import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { showToastErrorMessage, showToastSuccessMessage } from '../../../../features/toastSlice';
import { axios } from '../../../../helpers/apiHelper';
import { fetchIdeas } from '../../../../features/actionsSlice';

function NewIdea({ closeModal, ideaModules }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t('error_message.required_field')),
        description: Yup.string().notRequired(),
        module: Yup.string().required(4, t('error_message.required_field'))
    });
    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { module: ideaModules?.length > 0 ? '' : ideaModules[0]?._id } });

    const createNewIdea = async () => {
        try {
            await axios.post('/task/createIdea', { ideaData: getValues() });
            closeModal();
            await dispatch(fetchIdeas(getValues().module));
            dispatch(showToastSuccessMessage(t('success_message.dashboard.created_idea_successfully')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.dashboard.creating_idea_failed')));
        }
    };

    useEffect(() => {
        if (ideaModules && ideaModules?.length === 1) {
            setValue('module', ideaModules[0]?._id);
        }
    }, [ideaModules]);

    return (
        <Modal show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100 mt-2">
                    <span>{t('content.create_new_idea')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {ideaModules?.length ?
                    <Form onSubmit={handleSubmit(createNewIdea)} className="container d-flex flex-column">
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1">{t('content.add_idea_to_module')}</Form.Label>
                            <Form.Select
                                className="shadow-none border-2 mb-1 border-color-primary"
                                isInvalid={errors.module?.message}
                                placeholder={t('content.select_module')}
                                name="module"
                                {...register('module')}
                            >
                                <option key="000" value="">{t('best_contribution.please_select')}</option>
                                {ideaModules?.map(module => (
                                    <option value={module?._id} key={module?._id}>{module?.title}</option>
                                ))}
                            </Form.Select>
                            {errors.module?.message &&
                                <Form.Label className="error-placeholder mb-2">{errors.module?.message}</Form.Label>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                name="title"
                                {...register('title')}
                                placeholder={t('content.new_idea_title')}
                                isInvalid={errors.title?.message}
                                className="shadow-none border-2 border-color-primary"
                            />
                            {errors.title?.message && <Form.Label className="error-placeholder">{errors.title?.message}</Form.Label>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                name="description"
                                {...register('description')}
                                placeholder={t('content.this_is_a_new_idea')}
                                className="shadow-none mb-4 border-2 border-color-primary"
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-between">
                            <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                                {t('dashboard_shared.close')}
                            </Button>
                            <Button type="submit" className="primary-button" style={{ width: '65%' }}>
                                {t('dashboard_shared.create')}
                            </Button>
                        </div>
                    </Form> :
                    <div className="d-flex flex-column align-items-center">
                        <p className="text-center mb-5">
                            {t('content.at_least_one_ideas_module')}
                        </p>
                        <Button className="primary-button w-50" onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                    </div>}
            </Modal.Body>
        </Modal>
    );
}

export default NewIdea;