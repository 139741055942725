import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { editTaskToggle } from '../../../../features/actionsSlice';
import PermissionWrapper from '../../../permissionWrapper';
import { ROLES_PERMISSIONS } from '../../../../constants';
import CustomModal from '../../modalCustom';

function Idea({ idea, deleteIdea }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [areYouSureDeleteIdeaModule, setAreYouSureDeleteIdeaModule] = useState(false);

    const toggleAreYouSureDeleteIdeaModule = () => {
        setAreYouSureDeleteIdeaModule(prevState => !prevState);
    };

    const deleteIdeaAndCloseTheModule = async () => {
        await deleteIdea(idea);
        toggleAreYouSureDeleteIdeaModule();
    };

    return (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <div className="card idea mt-3">
                <div className="idea-header">{idea.title}</div>
                <p className="idea-content">{idea.description}</p>
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_WRITE]}>
                    <div className="d-flex">
                        <Button
                            variant="link"
                            className="mt-auto p-2 convert-to-action-button"
                            onClick={() => dispatch(editTaskToggle({ isOpened: true, task: idea, editIdeas: true }))}
                        >
                            <i className="bi bi-pencil-square cursor visible-icon"/>
                        </Button>
                        <Button
                            variant="link"
                            className="mt-auto p-2 convert-to-action-button"
                            onClick={toggleAreYouSureDeleteIdeaModule}
                        >
                            <i className="bi bi-trash cursor visible-icon"/>
                        </Button>
                    </div>
                </PermissionWrapper>
            </div>
            {areYouSureDeleteIdeaModule && (
                <CustomModal
                    title={`${t('content.delete_idea')} ${idea.title}`}
                    text1={t('content.are_you_sure_delete_idea')}
                    secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                    primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                    onPrimaryButtonClick={deleteIdeaAndCloseTheModule}
                    onSecondaryButtonClick={toggleAreYouSureDeleteIdeaModule}
                />
            )}
        </div>
    );
}

export default Idea;
