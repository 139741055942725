import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage, showToastMessage, showToastSuccessMessage } from '../../../features/toastSlice';
import PermissionWrapper from '../../permissionWrapper';
import { ROLES_PERMISSIONS, TEXTBOX_MODULE_DESCRIPTIONS } from '../../../constants';

function Textbox({ module }) {
    const { t } = useTranslation();
    const [textboxModule, setTextboxModule] = useState();
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        description: Yup.string().notRequired()
    });
    const {
        handleSubmit,
        register,
        getValues,
        reset,
        formState: { isDirty }
    } = useForm({ resolver: yupResolver(validationSchema) });

    const fetchTextbox = async () => {
        try {
            const textbox = await axios.get(`textbox?moduleId=${module._id}`);
            reset();
            setTextboxModule(textbox.data);
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.dashboard.failed_loading_data')));
        }
    };

    useEffect(() => {
        (async () => {
            await fetchTextbox();
        })();
    }, [module]);

    const saveChanges = async () => {
        if (isDirty) {
            try {
                await axios.put(`textbox/${textboxModule._id}`, { textboxData: getValues() });
                await fetchTextbox();
                dispatch(showToastSuccessMessage(t('success_message.content.data_saved')));
            } catch (error) {
                dispatch(showToastErrorMessage(t('error_message.content.saving_data_failed')));
            }
        } else {
            dispatch(showToastMessage({ type: 'warning', message: t('warning_message.no_changes_made') }));
        }
    };

    return (
        <PermissionWrapper allowed={[ROLES_PERMISSIONS.TEXTBOX_READ]} displayNoAccessMessage>
            {textboxModule ?
                <div className="container-xl textbox-container">
                    <h2>{textboxModule?.module?.title}</h2>
                    <p>{t(TEXTBOX_MODULE_DESCRIPTIONS[textboxModule?.placeholderDescription])}</p>
                    <Form className="textbox" onSubmit={handleSubmit(saveChanges)}>
                        {/* PO requested this component to be removed, but we will maybe need it later on */}
                        {/* <PermissionWrapper
                            allowed={[ROLES_PERMISSIONS.TEXTBOX_WRITE]}
                            fallbackComponent={
                                <Form.Check
                                    className="switch my-4"
                                    id="custom-switch"
                                    type="switch"
                                    label="Show on dashboard"
                                    name="showOnDashboard"
                                    disabled
                                    {...register('showOnDashboard', { value: textboxModule?.showOnDashboard || false })}
                                />
                            }
                        >
                            <Form.Check
                                className="switch my-4"
                                id="custom-switch"
                                type="switch"
                                label="Show on dashboard"
                                name="showOnDashboard"
                                {...register('showOnDashboard', { value: textboxModule?.showOnDashboard || false })}
                            />
                        </PermissionWrapper> */}
                        <PermissionWrapper
                            allowed={[ROLES_PERMISSIONS.TEXTBOX_WRITE]}
                            fallbackComponent={
                                <Form.Control
                                    className="textarea-input mb-4"
                                    as="textarea"
                                    {...register('description', { value: textboxModule?.description || '' })}
                                    placeholder={t('dashboard_shared.description')}
                                    disabled
                                    name="description"
                                />
                            }
                        >
                            <Form.Control
                                className="textarea-input mb-4 shadow-none"
                                as="textarea"
                                {...register('description', { value: textboxModule?.description || '' })}
                                placeholder={t('dashboard_shared.description')}
                                name="description"
                            />
                        </PermissionWrapper>
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.TEXTBOX_WRITE]}>
                            <Button type="submit" className="primary-button w-50">
                                {t('content.save')}
                            </Button>
                        </PermissionWrapper>
                    </Form>
                </div> :
                <div className="d-flex justify-content-center m-5 loader-wrapper">
                    <div className="spinner-grow text-info loader" role="status"/>
                    <span className="loader-text">{t('dashboard_shared.loading')}</span>
                </div>}
        </PermissionWrapper>
    );
}

export default Textbox;
