import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { COLORS } from '../../../constants';

function EditCompany({ closeModal, company, editCompany, readOnly }) {
    const { t } = useTranslation();
    const [logo, setLogo] = useState(company?.logo);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error_message.required_field')),
        color: Yup.string()
    });
    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        watch,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });
    const watchColor = watch('color');

    const saveAndPreviewLogo = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            // convert image file to base64 string
            setLogo(reader.result);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const removeLogo = () => {
        document.getElementById('logo').value = '';
        setLogo();
    };

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t(`administration.${readOnly ? 'details' : 'edit_company'}`)}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(async () => editCompany({ ...getValues(), logo }))}
                    className="container d-flex flex-column"
                >
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.teams_table.name')}</Form.Label>
                        <Form.Control
                            name="name"
                            {...register('name', { value: company?.name || '' })}
                            error={errors.name?.message}
                            readOnly={readOnly}
                            className="shadow-none border-color-primary"
                        />
                        {errors.name?.message && <Form.Label className="error-placeholder">{errors.name?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {!readOnly &&
                            <>
                                <Form.Label>{t('administration.upload_logo_image')}</Form.Label>
                                <Form.Control
                                    id="logo"
                                    type="file"
                                    onChange={saveAndPreviewLogo}
                                    className="shadow-none border-color-primary"
                                    accept="image/*"
                                />
                            </>
                        }
                        {logo && (
                            <div className="d-flex flex-column">
                                <p className="mt-2">{t('administration.logo_preview')}</p>
                                <div className={`d-flex ${!readOnly && 'justify-content-around'}`}>
                                    <img src={logo} alt="logo" className="img-fluid p-2"/>
                                    {!readOnly &&
                                        <Button className="secondary-button mt-2 align-self-center" onClick={removeLogo}>
                                            {t('administration.remove_logo')}
                                        </Button>}
                                </div>
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-2">{t(`administration.teams_table.${readOnly ? 'selected_color' : 'color'}`)}</Form.Label>
                        <div
                            className={`d-flex 
                                ${!readOnly && watchColor && watchColor.toUpperCase() !== COLORS.PRIMARY_COLOR.toUpperCase() &&
                                ' justify-content-around'}`
                            }
                        >
                            <Form.Control
                                type="color"
                                name="color"
                                {...register('color', { value: company?.color || COLORS.PRIMARY_COLOR })}
                                error={errors.color?.message}
                                disabled={readOnly}
                                className="shadow-none border-color-primary"
                            />
                            {!readOnly && watchColor && watchColor.toUpperCase() !== COLORS.PRIMARY_COLOR.toUpperCase() && (
                                <Button
                                    className="secondary-button w-75"
                                    onClick={() => setValue('color', COLORS.PRIMARY_COLOR, {
                                        shouldValidate: true,
                                        shouldDirty: true
                                    })}
                                >
                                    {t('administration.revert_to_default_color')}
                                </Button>
                            )}
                        </div>
                        {errors.color?.message && <Form.Label className="error-placeholder">{errors.color?.message}</Form.Label>}
                    </Form.Group>
                    {readOnly &&
                        <>
                            <p className="mb-2">
                                {t(`administration.${company?.admins?.length ? 'list_of_admins' : 'no_company_admins'}`)}
                            </p>
                            <div className="list-of-company-admins">
                                {company?.admins?.map(companyAdmin => companyAdmin?.isActive && (
                                    <div className="d-flex" key={companyAdmin?._id}>
                                        <i className="bi bi-dot"/>
                                        <span className="ps-1">
                                            {`${companyAdmin?.firstName} ${companyAdmin?.lastName} - ${companyAdmin.email}`}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                    <div className={`d-flex mt-4 ${readOnly ? 'flex-column align-items-center' : 'justify-content-between'}`}>
                        <Button className="secondary-button" style={{ width: readOnly ? '60%' : '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        {!readOnly &&
                            <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                                {t('administration.save_changes')}
                            </Button>
                        }
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditCompany;