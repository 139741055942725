import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { reloadPage } from '../helpers/generalHelpers';

function ErrorPage({ code, message, buttonLabel, onClick, showLinkToLoginPage }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="error-page w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="error-container d-flex flex-column align-items-center w-75">
                {code && <h1 style={{ color: 'gray' }}>{code}</h1>}
                <h4>{message || t('error_message.global_message')}</h4>
                {code && (
                    <Button
                        variant="link"
                        className="link shadow-none"
                        href={`mailto:${process.env.REACT_APP_EMAIL_FOR_USERS_SUPPORT}`}
                    >
                        <h5>{t('error_message.contact_the_administrator_for_help')}</h5>
                    </Button>
                ) }
                <Button className="primary-button m-3" onClick={onClick || reloadPage}>
                    {buttonLabel || t('dashboard_shared.reload')}
                </Button>
                {showLinkToLoginPage &&
                    <Button
                        variant="link"
                        className="link shadow-none"
                        onClick={() => navigate('/sign-in')}
                    >
                        {t('sign_up.go_to_login')}
                    </Button>
                }
            </div>
        </div>
    );
}

export default ErrorPage;