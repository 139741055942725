import { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { showToastErrorMessage, showToastMessage, showToastSuccessMessage } from '../../../features/toastSlice';
import { axios } from '../../../helpers/apiHelper';
import PermissionWrapper from '../../permissionWrapper';
import SortableTable from '../sortableTable';
import EditTeam from './editTeam';
import NewTeam from './newTeam';
import { ROLES_PERMISSIONS } from '../../../constants';
import { fetchUser } from '../../../features/agendaSlice';
import CustomModal from '../../dashboard/modalCustom';
import InviteUsers from '../users/inviteUsers';

function TeamsAdministration() {
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const [showInviteUsersModal, setShowInviteUsersModal] = useState(false);
    const [usersTeams, setUsersTeams] = useState();
    const [usersCompanies, setUsersCompanies] = useState();
    const [showEditTeamModal, setShowEditTeamModal] = useState(false);
    const [showAreYouSureYouWantToDeleteTeam, setShowAreYouSureYouWantToDeleteTeam] = useState(false);
    const [teamToEdit, setTeamToEdit] = useState();
    const [teamToInviteTo, setTeamToInviteTo] = useState();
    const [teamToDelete, setTeamToDelete] = useState();
    const [updateOfTeamsMade, setUpdateOfTeamsMade] = useState();
    const [teamsFetched, setTeamsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const usersLastSelectedTeamId = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?._id);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const openEditTeamModal = (team) => {
        setShowEditTeamModal(true);
        setTeamToEdit(team);
    };

    const toggleAreYouSureYouWantToDeleteTeamModal = () => {
        setShowAreYouSureYouWantToDeleteTeam(prevState => !prevState);
    };

    const toggleInviteUsersModal = (team) => {
        setShowInviteUsersModal(prevState => !prevState);
        setTeamToInviteTo(team);
    };

    const deleteTeam = async () => {
        try {
            setIsLoading(true);
            await axios.delete(`team/${teamToDelete?._id}`);
            setUpdateOfTeamsMade(true);
            setIsLoading(false);
            toggleAreYouSureYouWantToDeleteTeamModal();
            dispatch(showToastSuccessMessage(t('success_message.administration.team_successfully_deleted')));
        } catch (error) {
            setIsLoading(false);
            dispatch(showToastErrorMessage(t('error_message.administration.deleting_team_failed')));
        }
    };

    const openAreYouSureYouWantToDeleteTeamModal = (team) => {
        toggleAreYouSureYouWantToDeleteTeamModal();
        setTeamToDelete(team);
    };

    const teamsTableColumns = [
        { key: 'name',
            sortable: true,
            link: '/administration/users'
        },
        { key: 'companyName', sortable: true },
        // clickable columns can be disabled for some rows - disabledKey is used for that - row[column.disabledKey] returns true
        // if it's disabled and in that case the disabledMessage will be displayed in cases of icon fields
        {
            key: 'edit',
            icon: 'bi bi-pencil-square',
            restrictedAccess: ROLES_PERMISSIONS.TEAM_WRITE,
            onClick: openEditTeamModal
        },
        {
            key: 'delete',
            icon: 'bi bi-trash',
            disabledKey: 'disabledDeleting',
            restrictedAccess: ROLES_PERMISSIONS.TEAM_WRITE,
            onClick: openAreYouSureYouWantToDeleteTeamModal,
            headerSuffix: (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {t('administration.cant_delete_team_details')}
                        </Tooltip>
                    }
                >
                    <i className="bi bi-info-square ms-1"/>
                </OverlayTrigger>
            )
        },
        {
            key: 'addUser',
            icon: 'bi bi-plus-square',
            restrictedAccess: ROLES_PERMISSIONS.INVITE_WRITE,
            onClick: toggleInviteUsersModal
        }
    ];

    const fetchUsersTeams = async () => {
        setTeamsFetched(false);
        try {
            const allTeamsUserIsAdministrating = await axios.get('/team');
            setUsersTeams(allTeamsUserIsAdministrating?.data?.map(team => {
                const filteredOnlyActiveUsersInTheTeam = team?.users?.filter(user => user?.isActive);
                return (
                    {
                        ...team,
                        users: filteredOnlyActiveUsersInTheTeam,
                        disabledDeleting: filteredOnlyActiveUsersInTheTeam?.length > 0,
                        clickable: true
                    }
                );
            }));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.failed_users_teams_loading')));
        }
        setTeamsFetched(true);
    };

    const fetchUsersCompanies = async () => {
        try {
            const allCompaniesUserIsAdministrating = await axios.get('/company');
            setUsersCompanies(allCompaniesUserIsAdministrating?.data);
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.failed_users_companies_loading')));
        }
    };

    useEffect(() => {
        (async () => {
            await fetchUsersTeams();
            await fetchUsersCompanies();
        })();
    }, [usersLastSelectedTeamId]);

    useEffect(() => {
        if (updateOfTeamsMade) {
            (async () => {
                await fetchUsersTeams();
                await dispatch(fetchUser());
                setUpdateOfTeamsMade(false);
            })();
        }
    }, [updateOfTeamsMade]);

    const toggleCreateTeamModal = () => {
        setShowCreateTeamModal(prevState => !prevState);
    };

    const closeEditTeamModal = () => {
        setShowEditTeamModal(prevState => !prevState);
        setTeamToEdit();
    };

    const saveUpdatedTeam = async (isDirty, editedTeam) => {
        if (isDirty) {
            try {
                await axios.put(`/team/${teamToEdit?._id}`, { teamData: editedTeam });

                if (teamToEdit._id === usersLastSelectedTeamId) {
                    await dispatch(fetchUser());
                }

                setUsersTeams(prevState => prevState.map((team) => (team._id === teamToEdit._id ?
                    { ...teamToEdit, ...editedTeam } :
                    team
                )));
                setUpdateOfTeamsMade(true);
                closeEditTeamModal();
                dispatch(showToastSuccessMessage(t('success_message.administration.successfully_updated_team')));
            } catch (error) {
                dispatch(showToastErrorMessage(t('error_message.administration.failed_updating_team')));
            }
        } else {
            dispatch(showToastMessage({ type: 'warning', message: t('warning_message.no_changes_made') }));
        }
    };

    return (
        <div className="scrollableWrapper h-100">
            <div className="usersAdministration m-5 h-100 w-75">
                <div className="mt-5">
                    <h1>{t('administration.teams_administration')}</h1>
                </div>
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.TEAM_WRITE]}>
                    <Button variant="link" className="shadow-none my-3 p-0 link-to-invite fs-5" onClick={toggleCreateTeamModal}>
                        <span className="pe-2">{t('administration.create_new_team')}</span>
                        <i className="bi bi-plus-square cursor align-middle"/>
                    </Button>
                </PermissionWrapper>
                {showCreateTeamModal && (
                    <NewTeam
                        closeModal={toggleCreateTeamModal}
                        setUpdateOfTeamsMade={setUpdateOfTeamsMade}
                        companies={usersCompanies}
                    />
                )}
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.TEAM_READ]}>
                    <div className="d-flex flex-column align-items-start my-4 pb-5">
                        <h3>{t('administration.teams_table_headline')}</h3>
                        {teamsFetched && usersTeams?.length > 0 &&
                            <SortableTable rows={usersTeams} columns={teamsTableColumns} translationPath="administration.teams_table"/>
                        }
                        {teamsFetched && usersTeams?.length === 0 && <p>{t('administration.no_teams_available')}</p>}
                        {!teamsFetched && <Spinner animation="border"/>}
                    </div>
                </PermissionWrapper>
                {showInviteUsersModal && (
                    <InviteUsers
                        closeModal={toggleInviteUsersModal}
                        teamToInviteTo={teamToInviteTo}
                    />
                )}
                {showEditTeamModal &&
                    <EditTeam
                        closeModal={closeEditTeamModal}
                        team={teamToEdit}
                        editTeam={saveUpdatedTeam}
                    />
                }
                {showAreYouSureYouWantToDeleteTeam && (
                    <CustomModal
                        title={`${t('administration.delete_team')} ${teamToDelete?.name}`}
                        text1={t('administration.are_you_sure_you_want_to_delete_team')}
                        warningText={t('administration.team_will_be_temporarily_deleted')}
                        secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                        primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                        onPrimaryButtonClick={deleteTeam}
                        onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteTeamModal}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
}

export default TeamsAdministration;
