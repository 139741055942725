
import { useState, useEffect } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ROLES_PERMISSIONS } from '../../../constants';
import { editAgendaInfoToggle, saveAgendaInfo } from '../../../features/agendaSlice';
import PermissionWrapper from '../../permissionWrapper';

function AgendaInfo() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const teamData = useSelector((state) => state.agenda?.userData?.lastSelectedTeam);
    const { editAgendaInfo } = useSelector((state) => state.agenda);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setTitle(teamData.agendaTitle);
        setDescription(teamData.agendaDescription);
    }, [teamData]);

    const save = async () => {
        await dispatch(saveAgendaInfo({ title, description }));
        dispatch(editAgendaInfoToggle());
    };

    const cancel = () => {
        setTitle(teamData.agendaTitle);
        setDescription(teamData.agendaDescription);
        dispatch(editAgendaInfoToggle());
    };

    return (
        <div>
            {editAgendaInfo ?
                <div className="d-flex align-items-end">
                    <Form.Group className="w-50">
                        <Form.Label className="text-secondary">{t('dashboard_shared.title')}</Form.Label>
                        <Form.Control
                            className="h-25 p-1 border-color-primary shadow-none"
                            placeholder={t('dashboard_shared.title')}
                            name="title"
                            defaultValue={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </Form.Group>
                    <FormGroup className="w-50 ms-2">
                        <Form.Label className="text-secondary">{t('dashboard_shared.description')}</Form.Label>
                        <Form.Control
                            className="h-25 p-1 border-color-primary shadow-none"
                            placeholder={t('dashboard_shared.description')}
                            name="description"
                            defaultValue={description}
                            onChange={(event) => setDescription(event.target.value)}
                        />
                    </FormGroup>
                    <i className="bi bi-check-square ms-2 visible-icon cursor" onClick={save}/>
                    <i className="bi bi-x-square ms-2 visible-icon cursor" onClick={cancel}/>
                </div> :
                <div className="d-flex agenda-info justify-content-between hover-area pe-2">
                    <span className="ps-2 pe-4">{title || t('agenda.title')}</span>
                    <span className="ps-2 pe-4 text-secondary">{description || t('agenda.description')}</span>
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                        <i className="bi bi-pencil-square ms-2 visible-icon" onClick={cancel}/>
                    </PermissionWrapper>
                </div>
            }
        </div>
    );
}

export default AgendaInfo;

