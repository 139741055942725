import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { REGEX } from '../constants';
import { showToastErrorMessage } from '../features/toastSlice';
import { axios } from '../helpers/apiHelper';
import ErrorPage from './errorPage';

function ChangePassword() {
    const { guid: resetPasswordLinkGuid } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLinkChecked, setIsLinkChecked] = useState(false);
    const [isLinkValid, setIsLinkValid] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [errorCheckingGuid, setErrorCheckingGuid] = useState();
    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t('error_message.required_field')).matches(
            REGEX.PASSWORD,
            { message: t('error_message.password_format_message') }
        ),
        confirmPassword: Yup.string().required(t('error_message.required_field'))
            .oneOf([Yup.ref('password')], t('error_message.passwords_do_not_match'))
    });
    const {
        handleSubmit,
        register,
        formState: { errors },
        getValues
    } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        if (!isLinkChecked) {
            (async () => {
                try {
                    await axios.get(`user/checkExpirationOfPasswordResetLink?guid=${resetPasswordLinkGuid}`);
                    setIsLinkValid(true);
                    localStorage.removeItem('user');
                    localStorage.removeItem('x-access-token');
                } catch (err) {
                    setIsLinkValid(false);
                    setErrorCheckingGuid(err);
                }
                setIsLinkChecked(true);
            })();
        }
    }, [resetPasswordLinkGuid]);

    const changePassword = async () => {
        try {
            await axios.put('user/updatePassword', { guid: resetPasswordLinkGuid, password: getValues('password') });
            setIsPasswordChanged(true);
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.sign_in.changing_password_failed')));
        }
    };

    return (
        <>
            {isLinkChecked && isLinkValid &&
                <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="container password-container border col col-xs-10 col-lg-6 m-auto p-5">
                        {!isPasswordChanged ?
                            <>
                                <Button
                                    variant="link"
                                    className="link align-self-end shadow-none"
                                    onClick={() => navigate('/sign-in')}
                                >
                                    {t('sign_up.go_back_to_sign_in')}
                                </Button>
                                <h2 className="align-self-start mt-2 mb-4">{t('sign_up.change_your_password')}</h2>
                                <Form className="w-100" onSubmit={handleSubmit(changePassword)}>
                                    <p>
                                        {`${t('sign_up.change_your_password_description')} ${t('error_message.password_format_message')}`}
                                    </p>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-0">{t('sign_up.new_password')}</Form.Label>
                                        <Form.Control
                                            name="password"
                                            type="password"
                                            {...register('password')}
                                            placeholder={t('sign_up.password')}
                                            isInvalid={errors.password?.message}
                                            className="shadow-none"
                                        />
                                        {errors.password?.message &&
                                            <Form.Label className="error-placeholder">{errors.password?.message}</Form.Label>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label className="mb-0">{t('sign_up.confirm_password')}</Form.Label>
                                        <Form.Control
                                            name="confirmPassword"
                                            type="password"
                                            {...register('confirmPassword')}
                                            placeholder={t('sign_up.confirm')}
                                            isInvalid={errors.confirmPassword?.message}
                                            className="shadow-none"
                                        />
                                        {errors.confirmPassword?.message &&
                                            <Form.Label className="error-placeholder">{errors.confirmPassword?.message}</Form.Label>
                                        }
                                    </Form.Group>
                                    <Button type="submit" className="primary-button w-100 py-2">
                                        {t('sign_up.save_new_password')}
                                    </Button>
                                </Form>
                            </> :
                            <div className="d-flex flex-column align-items-center">
                                <i className="bi bi-check-circle success-icon"/>
                                <h5 className="my-4">{t('sign_up.password_changed_successfuly')}</h5>
                                <Button onClick={() => navigate('/sign-in')} className="primary-button w-100 py-2 mt-2">
                                    {t('sign_up.login')}
                                </Button>
                            </div>}
                    </div>
                </div>}
            {isLinkChecked && !isLinkValid &&
                <ErrorPage
                    code={errorCheckingGuid?.response?.data?.status}
                    message={t('sign_up.change_password_link_does_not_exist')}
                />}
        </>
    );
}

export default ChangePassword;