import { useEffect, useState } from 'react';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { fetchUsers } from '../../../features/actionsSlice';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../features/toastSlice';
import { axios } from '../../../helpers/apiHelper';
import PermissionWrapper from '../../permissionWrapper';
import { ROLES_PERMISSIONS } from '../../../constants';
import { reformatToISODateString } from '../../../helpers/generalHelpers';
import CustomModal from '../modalCustom';

function Attendance({ module }) {
    const [previousMeetings, setPreviousMeetings] = useState();
    const [failedSubmitting, setFailedSubmitting] = useState(false);
    const [meetingToDelete, setMeetingToDelete] = useState();
    const [showAreYouSureYouWantToDeleteMeetingModal, setShowAreYouSureYouWantToDeleteMeetingModal] = useState(false);
    const teamId = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?._id);
    const { users } = useSelector((state) => state.actions);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        notes: Yup.string().notRequired(),
        attendees: Yup.array().of(Yup.bool()).test({
            message: t('attendance.need_to_select_at_least_one_user'),
            test: arr => arr?.filter((el) => el).length > 0
        })
    });
    const {
        register,
        handleSubmit,
        reset,
        trigger,
        getValues,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });

    const fetchPreviousMeetings = async () => {
        try {
            const previousMeetingsRes = await axios.get(`/attendance?teamId=${teamId}&moduleId=${module._id}`);
            setPreviousMeetings(previousMeetingsRes?.data?.map(meeting => ({
                ...meeting,
                meetingDate: new Date(meeting?.meetingDate),
                isOpenedInfo: false
            })));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.dashboard.failed_loading_data')));
        }
    };

    useEffect(() => {
        try {
            (async () => {
                await dispatch(fetchUsers(teamId));
                await fetchPreviousMeetings();
            })();
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.dashboard.failed_loading_data')));
        }
    }, [module, teamId]);

    const toggleMeetingInfo = (e, i) => {
        e.stopPropagation();
        setPreviousMeetings(prevState => prevState.map((meeting, index) => (
            index === i ? { ...meeting, isOpenedInfo: !meeting.isOpenedInfo } : meeting
        )));
    };

    const createMeeting = async () => {
        const meetingData = getValues();
        try {
            await axios.post('/attendance', { meetingData: {
                notes: meetingData.notes,
                attendees: users?.filter((user, i) => meetingData.attendees[i]),
                numberOfUsersInTeamAtTimeOfMeeting: users?.length,
                module: module._id,
                team: teamId
            } });
            reset();
            await fetchPreviousMeetings();
            dispatch(showToastSuccessMessage(t('success_message.content.data_saved')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.content.saving_data_failed')));
        }
    };

    const toggleAreYouSureYouWantToDeleteMeetingModal = () => {
        setShowAreYouSureYouWantToDeleteMeetingModal(prevState => !prevState);
    };

    const openAreYouSureYouWantToDeleteMeetingModal = (e, meeting) => {
        e.stopPropagation();
        setMeetingToDelete(meeting);
        toggleAreYouSureYouWantToDeleteMeetingModal();
    };

    const deleteMeeting = async () => {
        try {
            await axios.delete(`attendance/${meetingToDelete?._id}`);
            await fetchPreviousMeetings();
            setMeetingToDelete();
            toggleAreYouSureYouWantToDeleteMeetingModal();
            dispatch(showToastSuccessMessage(t('success_message.content.meeting_successfully_deleted')));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.content.deleting_meeting_failed')));
        }
    };

    return (
        <PermissionWrapper allowed={[ROLES_PERMISSIONS.ATTENDANCE_READ]} displayNoAccessMessage>
            <div className="attendance">
                <h2>{module?.title}</h2>
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.ATTENDANCE_WRITE]}>
                    <p>{t('attendance.attendance_description')}</p>
                </PermissionWrapper>
                <div className="attendance-content">
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.ATTENDANCE_WRITE]}>
                        <Form className="attendance-form">
                            <h5 className="my-2">{t('attendance.todays_meeting')}</h5>
                            <div className="check-list">
                                {users && users?.length === 0 && <p>{t('attendance.no_users_to_attend')}</p>}
                                {users?.map((user, i) => user?.isActive && (
                                    <Form.Check
                                        key={user?._id}
                                        className="checkbox"
                                        type="checkbox"
                                        name={`attendees[${i}]`}
                                        {...register(`attendees.${i}`)}
                                        onClick={async () => {
                                            if (failedSubmitting) {
                                                await trigger('attendees');
                                            }
                                        }}
                                        label={
                                            <span>
                                                {`${user?.firstName} ${user?.lastName}`}
                                                {user?.teamsThatUserIsTeamLeadOf?.includes(teamId) && (
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={
                                                            <Tooltip>
                                                                {t('administration.team_lead')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="bi bi-person-check-fill ms-2"/>
                                                    </OverlayTrigger>)}
                                            </span>
                                        }
                                    />
                                ))}
                                <Form.Label className="error-placeholder small">{errors?.attendees?.message}</Form.Label>
                            </div>
                            <Form.Label className="mt-4 small">{t('attendance.notes')}</Form.Label>
                            <Form.Control
                                className="notes-textarea mb-4 shadow-none"
                                as="textarea"
                                rows={4}
                                name="notes"
                                {...register('notes', { value: '' })}
                            />
                            <Button
                                type="submit"
                                className="primary-button"
                                onClick={handleSubmit(createMeeting, () => setFailedSubmitting(true))}
                            >
                                {t('attendance.save_attendance_as_new_meeting')}
                            </Button>
                        </Form>
                    </PermissionWrapper>
                    <div className="attendance-previous-meetings">
                        <h5 className="my-2">{t('attendance.previous_meetings')}</h5>
                        <div className="previous-meetings">
                            {previousMeetings && !previousMeetings?.length && <p>{t('attendance.there_are_no_previous_meetings')}</p>}
                            {previousMeetings?.map((meeting, i) => (
                                <div key={meeting?._id} className="meeting mb-1">
                                    <div className="meeting-headline px-3 py-1" onClick={(e) => toggleMeetingInfo(e, i)}>
                                        <span style={{ color: 'grey' }}>{reformatToISODateString(meeting?.meetingDate)}</span>
                                        <span>
                                            {`${meeting?.attendees.length}${t('attendance.x_of_y_present_middle')}
                                        ${meeting?.numberOfUsersInTeamAtTimeOfMeeting}${t('attendance.x_of_y_present_suff')}`}
                                        </span>
                                        <span>
                                            <PermissionWrapper allowed={[ROLES_PERMISSIONS.ATTENDANCE_WRITE]}>
                                                <i
                                                    className="bi bi-trash icon-gray me-2"
                                                    onClick={(e) => openAreYouSureYouWantToDeleteMeetingModal(e, meeting)}
                                                />
                                            </PermissionWrapper>
                                            <i
                                                className={meeting?.isOpenedInfo ?
                                                    'bi bi-chevron-up sort-icon' : 'bi bi-chevron-down sort-icon'}
                                                onClick={(e) => toggleMeetingInfo(e, i)}
                                            />
                                        </span>
                                    </div>
                                    {meeting?.isOpenedInfo &&
                                        <div className="meeting-info">
                                            {meeting?.notes && <p className="small m-2">{meeting.notes}</p>}
                                            {meeting?.attendees?.map(user => (
                                                <div key={user?._id} className="d-flex align-items-center my-0 ms-1">
                                                    <i className="bi bi-dot cursor"/>
                                                    <span className="small">
                                                        {`${user?.firstName} ${user?.lastName}`}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {showAreYouSureYouWantToDeleteMeetingModal && (
                    <CustomModal
                        title={`${t('content.delete_meeting_held')} ${reformatToISODateString(new Date(meetingToDelete?.meetingDate))}`}
                        text1={t('content.are_you_sure_you_want_to_delete_meeting')}
                        secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                        primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                        onPrimaryButtonClick={deleteMeeting}
                        onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteMeetingModal}
                    />
                )}
            </div>
        </PermissionWrapper>
    );
}

export default Attendance;
