import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import CustomToast from '../components/customToast';
import { handleSwitchingLastSelectedTeam, logout } from '../features/agendaSlice';

function LayoutWithToastMessages() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleInvalidToken = e => {
            if (e.key === 'x-access-token' && e.oldValue && !e.newValue) {
                // if user logs out in another tab
                dispatch(logout());
            }

            if (e.key === 'x-access-token' && !e.oldValue && e.newValue) {
                // if user logs in in another tab
                navigate('/');
            }

            if (e.key === 'user' && e.oldValue && e.newValue) {
                // if user switches last selected team in another tab
                const oldTeamId = JSON.parse(e.oldValue).lastSelectedTeam._id;
                const newTeamId = JSON.parse(e.newValue).lastSelectedTeam._id;
                if (oldTeamId !== newTeamId) {
                    (async () => dispatch(handleSwitchingLastSelectedTeam()))();
                }
            }
        };

        window.addEventListener('storage', handleInvalidToken);

        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken);
        };
    }, []);

    return (
        <>
            <Outlet/>
            <CustomToast/>
        </>
    );
}

export default LayoutWithToastMessages;