import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { MODULE_TYPES, ROLES_PERMISSIONS } from '../constants';
import { showToastErrorMessage, showToastSuccessMessage } from '../features/toastSlice';
import { axios } from '../helpers/apiHelper';
import EditTask from '../components/dashboard/content/actionPlan/editTask';
import NewIdea from '../components/dashboard/content/ideas/newIdea';
import { editTaskToggle } from '../features/actionsSlice';
import PermissionWrapper from '../components/permissionWrapper';

function AddIdeaOrActionLayout() {
    const [showAddNewIdeaActionMenu, setShowNewIdeaActionMenu] = useState(false);
    const [showNewIdeaModal, setShowNewIdeaModal] = useState(false);
    const [ideaModules, setIdeaModules] = useState();
    const sections = useSelector((state) => state.agenda?.sections);
    const editAgendaOpened = useSelector(state => state.agenda?.showAgendaModal);
    const { showEditTask, createAction } = useSelector((state) => state.actions);
    const usersLastSelectedTeamId = useSelector(state => state.agenda?.userData?.lastSelectedTeam?._id);
    const menuDivComponentsRef = useRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const toggleNewIdeaActionMenu = () => setShowNewIdeaActionMenu(prevState => !prevState);

    const handleClickOutsideOfTheMenu = (event) => {
        if (menuDivComponentsRef.current && !menuDivComponentsRef.current.contains(event.target)) {
            setShowNewIdeaActionMenu(false);
        }
    };

    const toggleNewIdeaModal = () => {
        if (!showNewIdeaModal) {
            toggleNewIdeaActionMenu();
        }
        setShowNewIdeaModal(prevState => !prevState);
    };

    const openCreateNewActionModal = () => {
        toggleNewIdeaActionMenu();
        dispatch(editTaskToggle({ isOpened: true, task: {}, createAction: true }));
    };

    useEffect(() => {
        // if menu is opened and user clicks outside of the menu, the menu is closed
        document.addEventListener('click', handleClickOutsideOfTheMenu, false);
        return () => {
            document.removeEventListener('click', handleClickOutsideOfTheMenu, false);
        };
    }, []);

    useEffect(() => {
        if (!sections) {
            return;
        }

        const ideaModulesTemp = [];
        sections.forEach(({ modules }) => {
            if (!modules) {
                return;
            }

            modules.forEach((module) => {
                if (module?.type === MODULE_TYPES.IDEAS) {
                    ideaModulesTemp.push({ ...module });
                }
            });
        });
        setIdeaModules(ideaModulesTemp);
    }, [sections]);

    const createNewAction = async (taskData) => {
        try {
            await axios.post('/task', { taskData: { ...taskData, areaOfImprovement: taskData.areaOfImprovement || null } });
            dispatch(showToastSuccessMessage(t('success_message.dashboard.created_action_successfully')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.dashboard.creating_action_failed')));
        }
    };

    return (
        <>
            <Outlet/>
            {!editAgendaOpened &&
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_WRITE]}>
                    {usersLastSelectedTeamId &&
                        <div className="add-idea-or-action-container">
                            <div className="add-idea-or-action" ref={menuDivComponentsRef}>
                                {showAddNewIdeaActionMenu &&
                                    <>
                                        <Button className="primary-button shadow-lg mb-2 w-100 py-2" onClick={openCreateNewActionModal}>
                                            <span>
                                                {t('content.action')}
                                                <i className="bi bi-chevron-right ms-2"/>
                                            </span>
                                        </Button>
                                        <Button className="primary-button shadow-lg w-100 py-2" onClick={toggleNewIdeaModal}>
                                            <span>
                                                {t('content.idea')}
                                                <i className="bi bi-chevron-right ms-3"/>
                                            </span>
                                        </Button>
                                    </>}
                                <i onClick={toggleNewIdeaActionMenu} className="bi bi-plus-circle-fill add-idea-or-action-icon"/>
                            </div>
                        </div>}
                    {showNewIdeaModal && <NewIdea closeModal={toggleNewIdeaModal} ideaModules={ideaModules}/> }
                    {showEditTask && createAction && <EditTask save={createNewAction}/>}
                </PermissionWrapper>
            }
        </>
    );
}

export default AddIdeaOrActionLayout;