import { useTranslation } from 'react-i18next';
import ErrorPage from './errorPage';

function PageNotFound() {
    const { t } = useTranslation();
    return (
        <ErrorPage
            message={t('error_message.page_not_found')}
            buttonLabel={t('content.go_to_home_page')}
            onClick={() => { window.location.pathname = '/'; }}
        />
    );
}

export default PageNotFound;