import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
    moveUpSection,
    moveDownSection,
    editSectionToggle,
    saveSection,
    addNewModule,
    deleteSection,
    fetchSections
} from '../../../features/agendaSlice';
import PermissionWrapper from '../../permissionWrapper';
import { ROLES_PERMISSIONS } from '../../../constants';
import { showToastErrorMessage } from '../../../features/toastSlice';

function Section({ sectionIndex }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { sections, editSectionTitle } = useSelector((state) => state.agenda);
    const [section, setSection] = useState({});

    useEffect(() => {
        setSection(sections[sectionIndex]);
    }, [sections, sectionIndex]);

    const update = (value) => {
        const updatedSection = { ...section };
        updatedSection.title = value;
        setSection(updatedSection);
    };

    const save = async () => {
        await dispatch(saveSection({ sectionIndex, section }));
        dispatch(editSectionToggle({ sectionIndex, isOpened: false }));
    };

    const cancel = (isOpened) => {
        setSection(sections[sectionIndex]);
        dispatch(editSectionToggle({ sectionIndex, isOpened }));
    };

    const removeSection = async () => {
        const { _id } = sections[sectionIndex];
        if (section?.modules?.length > 0) {
            dispatch(showToastErrorMessage(t('error_message.agenda.cant_delete_section')));
        } else {
            await dispatch(deleteSection({ _id }));
            await dispatch(fetchSections());
        }
    };

    const moveUp = async () => {
        const sectionsToReorder = JSON.parse(JSON.stringify(sections));
        const sectionUp = sectionsToReorder[sectionIndex];
        const sectionDown = sectionsToReorder[sectionIndex - 1];
        sectionUp.order -= 1;
        sectionDown.order += 1;

        await dispatch(moveUpSection({ sections: [sectionUp, sectionDown], sectionIndex }));
    };

    const moveDown = async () => {
        const sectionsToReorder = JSON.parse(JSON.stringify(sections));
        const sectionDown = sectionsToReorder[sectionIndex];
        const sectionUp = sectionsToReorder[sectionIndex + 1];
        sectionDown.order += 1;
        sectionUp.order -= 1;

        await dispatch(moveDownSection({ sections: [sectionUp, sectionDown], sectionIndex }));
    };

    return (
        <div className="alert-secondary align-items-center rounded d-flex hover-area mb-2 section-title">
            <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                <div className="sorting-arrows cursor">
                    {(section?.order !== 1) && <i className="bi bi-caret-up-fill icon" onClick={moveUp}/>}
                    {(section?.order !== sections?.length) && <i className="bi bi-caret-down-fill icon" onClick={moveDown}/>}
                </div>
            </PermissionWrapper>
            <div className="section-header">
                {editSectionTitle[sectionIndex] ?
                    <div className="d-flex">
                        <Form.Control
                            className="agenda-section-title-edit border-color-primary shadow-none"
                            defaultValue={section?.title}
                            onChange={(event) => update(event.target.value)}
                        />
                        <i className="bi bi-check-square ms-2 visible-icon cursor" onClick={save}/>
                        <i className="bi bi-x-square ms-2 visible-icon cursor" onClick={() => cancel(false)}/>
                    </div> :
                    <span>
                        {section?.title}
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                            <i className="bi bi-pencil-square m-2 cursor icon visible-icon" onClick={() => cancel(true)}/>
                            <i className="bi bi-trash cursor icon visible-icon" onClick={removeSection}/>
                        </PermissionWrapper>
                    </span>
                }
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                    <span onClick={() => dispatch(addNewModule(sectionIndex))} className="cursor visible-icon">
                        {t('agenda.add_module')}
                        <i className="bi bi-plus-square m-2"/>
                    </span>
                </PermissionWrapper>
            </div>
        </div>
    );
}

export default Section;