import { useTranslation } from 'react-i18next';

import { AGENDA_MODULE_TYPES } from '../../../constants';

function ModuleTypes({ modulesThatCantBeAdded }) {
    const options = [];
    const { t } = useTranslation();

    Object.entries(AGENDA_MODULE_TYPES).map(([key, value]) => (
        options.push(
            <option
                key={key}
                disabled={modulesThatCantBeAdded?.includes(key?.toString())}
                value={key}
            >
                {t(`agenda.${value}`)}
            </option>
        )
    ));

    return options;
}

export default ModuleTypes;
