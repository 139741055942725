import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../features/toastSlice';

function NewTeam({ closeModal, setUpdateOfTeamsMade = () => {}, companies, companyToCreateTeamFor }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error_message.required_field')),
        company: Yup.string().required(t('error_message.required_field'))
    });
    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isDirty }
    } = useForm({ resolver: yupResolver(validationSchema) });

    const createTeam = async () => {
        try {
            await axios.post('/team', { teamData: getValues() });
            closeModal();
            setUpdateOfTeamsMade(true);
            dispatch(showToastSuccessMessage(t('success_message.administration.created_team_successfully')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.creating_team_failed')));
        }
    };

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('administration.create_new_team')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(async () => createTeam(isDirty, getValues()))} className="container d-flex flex-column">
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.teams_table.name')}</Form.Label>
                        <Form.Control
                            name="name"
                            {...register('name')}
                            error={errors.name?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.name?.message && <Form.Label className="error-placeholder">{errors.name?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-0">{t('administration.teams_table.companyName')}</Form.Label>
                        <Form.Select
                            className="form-control mb-3 shadow-none border-color-primary"
                            isInvalid={errors?.company}
                            placeholder={t('administration.teams_table.companyName')}
                            name="company"
                            defaultValue={companyToCreateTeamFor ? companyToCreateTeamFor?._id : ''}
                            {...register('company')}
                        >
                            <option key="000" value="">{t('best_contribution.please_select')}</option>
                            {companies?.map((company) => (
                                <option
                                    value={company?._id}
                                    key={company?._id}
                                >
                                    {company?.name}
                                </option>
                            ))}
                        </Form.Select>
                        {errors?.company && <Form.Label className="error-placeholder">{errors.company?.message}</Form.Label>}
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('dashboard_shared.create')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default NewTeam;