import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { showToastErrorMessage } from '../features/toastSlice';
import { fetchUser } from '../features/agendaSlice';
import Header from '../components/dashboard/header';
import PermissionWrapper from '../components/permissionWrapper';
import { ROLES_PERMISSIONS } from '../constants';

function DashboardLayout() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const { showEditTask, showBestContributionForm } = useSelector(state => state.actions);

    useEffect(() => {
        (async () => {
            try {
                await dispatch(fetchUser());
                setLoaded(true);
            } catch (error) {
                dispatch(showToastErrorMessage(t('error_message.dashboard.failed_users_data_loading')));
            }
        })();
    }, [dispatch]);

    return (
        loaded ?
            <>
                {(showEditTask || showBestContributionForm) && (
                    <PermissionWrapper allowed={[showEditTask ? ROLES_PERMISSIONS.TASK_WRITE : ROLES_PERMISSIONS.BEST_CONTRIBUTION_WRITE]}>
                        <div className="shadow-background"/>
                    </PermissionWrapper>
                ) }
                <Header/>
                <Outlet/>
            </> :
            <div className="d-flex justify-content-center m-5 loader-wrapper">
                <div className="spinner-grow text-info loader" role="status"/>
                <span className="loader-text">{t('dashboard_shared.loading')}</span>
            </div>
    );
}

export default DashboardLayout;