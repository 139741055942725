import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BestContributionTable from './bestContributionTable';
import { editBestContributionToggle, newAreaOfImprovementToggle } from '../../../../features/actionsSlice';
import NewAreaOfImprovement from './newAreaOfImprovement';
import PermissionWrapper from '../../../permissionWrapper';
import { ROLES_PERMISSIONS } from '../../../../constants';

function BestContribution({
    bestContribution,
    deleteBestContribution,
    setFilteredBestContributions
}) {
    const dispatch = useDispatch();
    const [colapsed, setColapsed] = useState(false);
    const { t } = useTranslation();
    const { showNewAreaOfImprovement } = useSelector(state => state.actions);

    const colapseHandler = () => {
        setColapsed(prevColapse => !prevColapse);
    };

    return (
        <div className="best-contribution">
            <h4 className="title">
                {colapsed ?
                    <i className="bi bi-arrows-expand cursor print-hide" onClick={colapseHandler} title="Expand"/> :
                    <i className="bi bi-arrows-collapse cursor print-hide" onClick={colapseHandler} title="Collapse"/>
                }
                {bestContribution?.title}
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.BEST_CONTRIBUTION_WRITE]}>
                    <i
                        className="bi bi-pencil-square m-2 cursor visible-icon print-hide"
                        onClick={() => dispatch(editBestContributionToggle({ isOpened: true, bestContribution }))}
                    />
                    <i
                        className="bi bi-trash cursor visible-icon print-hide"
                        onClick={() => deleteBestContribution(bestContribution)}
                    />
                </PermissionWrapper>
            </h4>
            <Collapse in={!colapsed}>
                <div id="best-contribution-block">
                    <span>{t('best_contribution.completion_rate')}</span>
                    <div className="progress">
                        <span
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${bestContribution?.completionRate}%` }}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            aria-label="completion rate"
                        />
                    </div>
                    <div className="mb-4">
                        {/* <div>{`${t('best_contribution.current_state')}: ${bestContribution?.startingPoint}`}</div>
                        <div>{`${t('best_contribution.desired_state')}: ${bestContribution?.desiredState}`}</div> */}

                        <div className="mb-1">
                            <strong>{`${t('best_contribution.current_state')}: `}</strong>
                            {!bestContribution?.startingPoint ?
                                `${t('best_contribution.please_define')}` :
                                bestContribution?.startingPoint }
                        </div>
                        <div>
                            <strong>{`${t('best_contribution.desired_state')}: `}</strong>
                            {!bestContribution?.desiredState ?
                                `${t('best_contribution.please_define')}` :
                                bestContribution?.desiredState }
                        </div>
                    </div>
                    {bestContribution?.areasOfImprovement?.map((areaOfImprovement) => (
                        <BestContributionTable
                            key={areaOfImprovement?._id}
                            title={areaOfImprovement?.title}
                            tasks={areaOfImprovement?.tasks}
                            areaOfImprovementToEdit={areaOfImprovement?._id}
                            bestContributionId={bestContribution._id}
                            setFilteredBestContributions={setFilteredBestContributions}
                        />
                    ))}
                    <BestContributionTable
                        title={t('content.no_area_of_improvement')}
                        tasks={bestContribution?.tasks}
                    />
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.BEST_CONTRIBUTION_WRITE]}>
                        {showNewAreaOfImprovement !== bestContribution?._id && (
                            <h5
                                className="cursor mb-3 print-hide"
                                onClick={() => dispatch(newAreaOfImprovementToggle({ bestContribution }))}
                            >
                                <i className="bi bi-plus-circle me-2"/>
                                {t('best_contribution.add_new_area')}
                            </h5>
                        )}
                        {showNewAreaOfImprovement === bestContribution?._id && <NewAreaOfImprovement/>}
                    </PermissionWrapper>
                </div>
            </Collapse>
        </div>
    );
}

export default BestContribution;
