import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../features/toastSlice';
import { COLORS } from '../../../constants';

function NewCompany({ closeModal, setUpdateOfCompaniesMade }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [logo, setLogo] = useState();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error_message.required_field')),
        color: Yup.string()
    });
    const {
        handleSubmit,
        register,
        getValues,
        setValue,
        watch,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });
    const watchColor = watch('color');

    const createCompany = async () => {
        try {
            await axios.post('/company', { companyData: { ...getValues(), logo } });
            closeModal();
            setUpdateOfCompaniesMade(true);
            dispatch(showToastSuccessMessage(t('success_message.administration.created_company_successfully')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.administration.creating_company_failed')));
        }
    };

    const saveAndPreviewLogo = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', () => {
            setLogo(reader.result);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const removeLogo = () => {
        document.getElementById('logo').value = '';
        setLogo();
    };

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('administration.create_new_company')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(async () => createCompany())} className="container d-flex flex-column">
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.teams_table.name')}</Form.Label>
                        <Form.Control
                            name="name"
                            {...register('name')}
                            error={errors.name?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.name?.message && <Form.Label className="error-placeholder">{errors.name?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.upload_logo_image')}</Form.Label>
                        <Form.Control
                            id="logo"
                            type="file"
                            onChange={saveAndPreviewLogo}
                            accept="image/*"
                            className="shadow-none border-color-primary"
                        />
                        {logo && (
                            <div>
                                <img src={logo} alt="logo" className="img-fluid p-2"/>
                                <Button className="primary-button sign-button" style={{ width: '65%' }} onClick={removeLogo}>
                                    {t('administration.remove_logo')}
                                </Button>
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-2">{t('administration.select_color')}</Form.Label>
                        <div
                            className={`d-flex ${watchColor && watchColor.toUpperCase() !== COLORS.PRIMARY_COLOR.toUpperCase() &&
                                 'justify-content-around'}`
                            }
                        >
                            <Form.Control
                                type="color"
                                name="color"
                                className="shadow-none border-color-primary"
                                {...register('color')}
                                defaultValue={COLORS.PRIMARY_COLOR}
                                error={errors.color?.message}
                            />
                            {watchColor && watchColor.toUpperCase() !== COLORS.PRIMARY_COLOR.toUpperCase() && (
                                <Button
                                    className="primary-button sign-button w-75"
                                    onClick={() => setValue('color', COLORS.PRIMARY_COLOR, {
                                        shouldValidate: true,
                                        shouldDirty: true
                                    })}
                                >
                                    {t('administration.revert_to_default_color')}
                                </Button>
                            )}
                        </div>
                        {errors.color?.message && <Form.Label className="error-placeholder">{errors.color?.message}</Form.Label>}
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('dashboard_shared.create')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default NewCompany;