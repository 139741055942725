import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { COLORS, ROLES_PERMISSIONS } from '../../../../constants';
import { fetchBestContributions } from '../../../../features/actionsSlice';
import { showToastErrorMessage, showToastMessage, showToastSuccessMessage } from '../../../../features/toastSlice';
import { axios } from '../../../../helpers/apiHelper';
import { reformatToISODateString } from '../../../../helpers/generalHelpers';
import PermissionWrapper from '../../../permissionWrapper';
import Chart from './barChart';
import EditGoalsAndMetricsBestContribution from './editBestContribution';
import AddBestContributionToGoalsAndMetrics from './addBestContribution';
import NewMeeting from './newMeeting';
import CustomModal from '../../modalCustom';

function GoalsAndMetrics({ module }) {
    const [teamBestContributionsById, setTeamBestContributionsById] = useState({});
    const [moduleData, setModuleData] = useState();
    const [chartLabels, setChartLabels] = useState([]);
    const [allBestContributionsDatasets, setAllBestContributionsDatasets] = useState([]);
    const [selectedBestContributionsDatasets, setSelectedBestContributionsDatasets] = useState([]);
    // for handling selected best contributions
    const [bestContributionsInModuleState, setBestContributionsInModuleState] = useState({});
    // endgoal and other values are not changeable
    const [bestContributionsWithoutDeletedInModuleById, setBestContributionsWithoutDeletedInModuleById] = useState({});
    const [bestContributionsWithoutDeletedInModule, setBestContributionsWithoutDeletedInModule] = useState([]);
    const [bestContributionsWithoutDeletedInModuleIds, setBestContributionsWithoutDeletedInModuleIds] = useState([]);
    // including deleted best contributions
    const [allBestContributionsInModuleById, setAllBestContributionsInModuleById] = useState({});
    const [previousMeetings, setPreviousMeetings] = useState();
    const [lastMeetingMeasurementsByBestContributionIds, setLastMeetingMeasurementsByBestContributionIds] = useState();
    const [showPreviousMeetings, setShowPreviousMeetings] = useState(false);
    const [moreThanOneBestContributionOnTheGraph, setMoreThanOneBestContributionOnTheGraph] = useState();
    const [chartYAxisMaxValue, setChartYAxisMaxValue] = useState();
    const [addNewBestContributionOpened, setAddNewBestContributionOpened] = useState(false);
    const [editBestContributionModalOpened, setEditBestContributionModalOpened] = useState(false);
    const [createNewMeetingModalOpened, setCreateNewMeetingModalOpened] = useState(false);
    const [bestContributionToEdit, setBestContributionToEdit] = useState();
    const [bestContributionToDelete, setBestContributionToDelete] = useState();
    const [meetingToDelete, setMeetingToDelete] = useState();
    const [showAreYouSureYouWantToDeleteBestContribution, setShowAreYouSureYouWantToDeleteBestContribution] = useState(false);
    const [showAreYouSureYouWantToDeleteMeetingModal, setShowAreYouSureYouWantToDeleteMeetingModal] = useState(false);
    const teamId = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?._id);
    const { teamBestContributions } = useSelector((state) => state.actions);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const bestContributionValidationSchema = Yup.object().shape({
        bestContribution: Yup.string().required(t('error_message.required_field')),
        endGoal: Yup.number().typeError(t('error_message.number_field')).required(t('error_message.required_field')),
        color: Yup.string().required(t('error_message.required_field'))
    });

    const fetchModule = async () => {
        try {
            const moduleRes = await axios.get(`goalsAndMetrics?moduleId=${module._id}`);
            setModuleData(moduleRes?.data);
            setPreviousMeetings(moduleRes?.data?.meetings?.map(meet => ({ ...meet, isOpenedInfo: false })));
            setChartLabels(moduleRes?.data?.meetings?.map((meet) => (reformatToISODateString(new Date(meet.meetingDate)))).reverse() || []);
        } catch (err) {
            dispatch(showToastErrorMessage('error_message.dashboard.failed_loading_data'));
        }
    };

    useEffect(() => {
        (async () => fetchModule())();
    }, [module]);

    useEffect(() => {
        if (teamId) {
            (async () => {
                dispatch(fetchBestContributions({ teamId }));
            })();
        }
    }, [teamId]);

    useEffect(() => {
        if (teamBestContributions && moduleData) {
            const teamBestContributionsByIdTemp = teamBestContributions.reduce((acc, bc) => ({ ...acc, [bc._id]: bc }), {});
            const filteredModuleBestContributions = moduleData?.bestContributions?.filter(bc => teamBestContributionsByIdTemp[bc._id]);
            setBestContributionsWithoutDeletedInModule(filteredModuleBestContributions);
            setBestContributionsWithoutDeletedInModuleById(filteredModuleBestContributions?.reduce((acc, bc) => (
                {
                    ...acc,
                    [bc._id]: bc
                }), {}));
            setBestContributionsWithoutDeletedInModuleIds(filteredModuleBestContributions?.map(bc => bc._id));
            setAllBestContributionsInModuleById(moduleData?.bestContributions?.reduce((acc, bc) => ({ ...acc, [bc._id]: bc }), {}));
            setTeamBestContributionsById(teamBestContributionsByIdTemp);
        }
    }, [teamBestContributions, moduleData]);

    const createSelectedBestContributionsDatasetsArray = () => {
        const selectedBestContributionsInModuleArray = Object.values(bestContributionsInModuleState)?.filter(bc => bc.selected)
            .map(bc => bc._id);
        const moreThanOneBestContributionOnTheGraphTemp = selectedBestContributionsInModuleArray?.length > 1;

        if (moreThanOneBestContributionOnTheGraphTemp) {
            setChartYAxisMaxValue(100);
        }

        setMoreThanOneBestContributionOnTheGraph(moreThanOneBestContributionOnTheGraphTemp);

        const data = allBestContributionsDatasets.filter(dataset => selectedBestContributionsInModuleArray.includes(
            dataset.bestContributionId
        )).map(dataset => {
            if (!moreThanOneBestContributionOnTheGraphTemp) {
                setChartYAxisMaxValue(dataset?.endGoal);
            }
            return ({
                ...dataset,
                data: dataset?.data?.map(dataObj => ({
                    ...dataObj,
                    y: moreThanOneBestContributionOnTheGraphTemp ? dataObj.percentageValue : dataObj.value
                }))
            });
        });
        setSelectedBestContributionsDatasets(data);
    };

    useEffect(() => {
        if (moduleData && teamBestContributionsById) {
            const allBestContributionsDatasetsTemp = [];
            bestContributionsWithoutDeletedInModule?.forEach(bc => {
                if (!bestContributionsInModuleState[bc._id]) {
                    setBestContributionsInModuleState(prevState => ({ ...prevState, [bc._id]: { ...bc, selected: true } }));
                }

                const datasetArray = [];

                moduleData?.meetings?.forEach((meet) => {
                    let value = false;
                    meet?.measurements?.forEach(measurement => {
                        if (measurement?.bestContribution === bc._id) {
                            value = measurement.currentState;
                        }
                    });
                    if (value !== false) {
                        datasetArray.push({
                            x: reformatToISODateString((new Date(meet.meetingDate))),
                            y: value,
                            percentageValue: (value && ((value / bc.endGoal) * 100)),
                            value,
                            label: teamBestContributionsById[bc._id]?.title || t('content.best_contribution_deleted'),
                            endGoal: bc?.endGoal
                        });
                    }
                });

                allBestContributionsDatasetsTemp.push(
                    {
                        bestContributionId: bc?._id,
                        label: teamBestContributionsById[bc?._id]?.title || t('content.best_contribution_deleted'),
                        data: datasetArray,
                        backgroundColor: bestContributionsWithoutDeletedInModuleById[bc?._id]?.color ||
                            bestContributionsInModuleState[bc._id]?.color,
                        minBarLength: 7,
                        maxBarThickness: 50,
                        endGoal: bc?.endGoal

                    }
                );
            });
            setAllBestContributionsDatasets(allBestContributionsDatasetsTemp);
        }
    }, [moduleData, teamBestContributionsById]);

    useEffect(() => {
        createSelectedBestContributionsDatasetsArray();
    }, [allBestContributionsDatasets]);

    const toggleMeetingInfo = (e, i) => {
        e.stopPropagation();
        setPreviousMeetings(prevState => prevState.map((meeting, index) => (
            index === i ? { ...meeting, isOpenedInfo: !meeting.isOpenedInfo } : meeting
        )));
    };

    const toggleShowPreviousMeetings = (e) => {
        e.stopPropagation();
        setShowPreviousMeetings(prevState => !prevState);
    };

    const toggleBestContributionAppearanceOnChart = (id) => {
        setBestContributionsInModuleState(prevState => ({ ...prevState, [id]: { ...prevState[id], selected: !prevState[id].selected } }));
    };

    useEffect(() => {
        createSelectedBestContributionsDatasetsArray();
    }, [bestContributionsInModuleState]);

    useEffect(() => {
        if (previousMeetings?.length) {
            const test = previousMeetings[0]?.measurements?.reduce((acc, measurment) => ({
                ...acc,
                [measurment.bestContribution]: measurment
            }), {});
            setLastMeetingMeasurementsByBestContributionIds(test);
        }
    }, [previousMeetings]);

    const toggleAddNewBestContributionOpened = () => setAddNewBestContributionOpened(prevState => !prevState);

    const toggleEditBestContributionModal = (bestContributionId) => {
        setBestContributionToEdit(bestContributionId && {
            ...bestContributionsWithoutDeletedInModuleById[bestContributionId],
            title: teamBestContributionsById[bestContributionId]?.title || t('content.best_contribution_deleted')
        });
        setEditBestContributionModalOpened(prevState => !prevState);
    };

    const toggleAreYouSureYouWantToDeleteMeetingModal = () => {
        setShowAreYouSureYouWantToDeleteMeetingModal(prevState => !prevState);
    };

    const toggleAreYouSureYouWantToDeleteBestContribution = (bestContributionId) => {
        setShowAreYouSureYouWantToDeleteBestContribution(prevState => !prevState);
        setBestContributionToDelete(bestContributionId && {
            ...bestContributionsWithoutDeletedInModuleById[bestContributionId],
            title: teamBestContributionsById[bestContributionId]?.title || t('content.best_contribution_deleted')
        });
    };

    const openAreYouSureYouWantToDeleteMeetingModal = (e, meeting) => {
        e.stopPropagation();
        setMeetingToDelete(meeting);
        toggleAreYouSureYouWantToDeleteMeetingModal();
    };

    const addBestContribution = async (bestContributionData) => {
        try {
            await axios.put(`goalsAndMetrics/addBestContribution?moduleId=${module?._id}`, { bestContribution: bestContributionData });
            await fetchModule();
            toggleAddNewBestContributionOpened();
            dispatch(showToastSuccessMessage(t('success_message.content.data_saved')));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.content.saving_data_failed')));
        }
    };

    const editBestContribution = async (bestContributionData, isDirty) => {
        if (isDirty) {
            try {
                await axios.put(`goalsAndMetrics/updateBestContribution?moduleId=${module?._id}&bestContributionId=${
                    bestContributionData?.bestContribution}`, { bestContributionData });
                await fetchModule();
                toggleEditBestContributionModal();
                dispatch(showToastSuccessMessage(t('success_message.content.successfully_updated_data')));
            } catch (err) {
                dispatch(showToastErrorMessage(t('error_message.content.updating_data_failed')));
            }
        } else {
            dispatch(showToastMessage({ type: 'warning', message: t('warning_message.no_changes_made') }));
        }
    };

    const createNewMeeting = async (meetingData) => {
        try {
            await axios.post('meeting', { meetingData: { ...meetingData, module: module?._id } });
            await fetchModule();
            setCreateNewMeetingModalOpened(false);
            dispatch(showToastSuccessMessage(t('success_message.content.data_saved')));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.content.saving_data_failed')));
        }
    };

    const deleteMeeting = async () => {
        try {
            await axios.delete(`meeting/${meetingToDelete?._id}?moduleId=${module?._id}`);
            await fetchModule();
            setMeetingToDelete();
            toggleAreYouSureYouWantToDeleteMeetingModal();
            dispatch(showToastSuccessMessage(t('success_message.content.meeting_successfully_deleted')));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.content.deleting_meeting_failed')));
        }
    };

    const deleteBestContribution = async () => {
        try {
            await axios.put(
                `goalsAndMetrics/removeBestContribution?moduleId=${module?._id}`,
                { bestContribution: bestContributionToDelete.bestContribution }
            );
            await fetchModule();
            setBestContributionToDelete();
            toggleAreYouSureYouWantToDeleteBestContribution();
            dispatch(showToastSuccessMessage(t('success_message.best_contribution.successfully_deleted_best_contribution')));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.best_contribution.deleting_best_contribution_failed')));
        }
    };

    return (
        <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_READ]} displayNoAccessMessage>
            <div className="scrollable-module pb-5 mb-5">
                <h2 className="pb-3">{module?.title}</h2>
                <div>
                    <Form>
                        {bestContributionsWithoutDeletedInModuleIds?.length ?
                            <>
                                <Row className="justify-content-between align-items-center mb-2">
                                    <Col className="d-none d-md-block">{t('best_contribution.best_contribution')}</Col>
                                    <Col className="d-none d-md-block" md={3} lg={3}>
                                        {`${t('content.end_goal')}: `}
                                    </Col>
                                    <Col className="d-none d-md-block" md={3} lg={3}>
                                        {`${t('content.current_state')}: `}
                                    </Col>
                                    <Col className="d-none d-lg-block" lg={1}/>
                                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_WRITE]}>
                                        <Col md={2} lg={1} className="d-none d-md-block text-center">
                                            {t('content.edit')}
                                        </Col>
                                    </PermissionWrapper>
                                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_WRITE]}>
                                        <Col md={2} lg={1} className="d-none d-md-block text-center">
                                            {t('content.delete')}
                                        </Col>
                                    </PermissionWrapper>
                                </Row>
                                {bestContributionsWithoutDeletedInModuleIds?.map(_id => (
                                    <Row key={_id} className="justify-content-between align-items-center mb-2">
                                        <Col xs={3} lg={3}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t('content.show_on_the_graph')}
                                                    </Tooltip>
                                                }
                                            >
                                                <Form.Check
                                                    className="checkbox box-check"
                                                    type="checkbox"
                                                    defaultChecked={bestContributionsInModuleState[_id]?.selected}
                                                    onClick={() => toggleBestContributionAppearanceOnChart(_id)}
                                                    label={teamBestContributionsById[_id]?.title || t('content.best_contribution_deleted')}
                                                />
                                            </OverlayTrigger>
                                        </Col>
                                        <Col xs={3} lg={3}>
                                            <Form.Control
                                                className="removed-shadow"
                                                disabled
                                                value={bestContributionsWithoutDeletedInModuleById[_id]?.endGoal}
                                            />
                                        </Col>
                                        <Col xs={3} lg={3}>
                                            <Form.Control
                                                className="removed-shadow"
                                                disabled
                                                value={lastMeetingMeasurementsByBestContributionIds ?
                                                    lastMeetingMeasurementsByBestContributionIds[_id]?.currentState || 0 : 0}
                                            />
                                        </Col>
                                        <Col className="d-none d-lg-block justify-content-around" lg={1}>
                                            <Form.Control
                                                className="w-100 removed-shadow"
                                                type="color"
                                                value={bestContributionsWithoutDeletedInModuleById[_id]?.color || COLORS.PRIMARY_COLOR}
                                                disabled
                                            />
                                        </Col>
                                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_WRITE]}>
                                            <Col xs={1} lg={1} className="text-center">
                                                <i
                                                    className="bi bi-pencil-square cursor visible-icon "
                                                    onClick={() => toggleEditBestContributionModal(_id)}
                                                />

                                            </Col>
                                        </PermissionWrapper>
                                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_WRITE]}>
                                            <Col xs={1} lg={1} className="justify-content-around text-center">
                                                <i
                                                    className="bi bi-trash cursor visible-icon"
                                                    onClick={() => toggleAreYouSureYouWantToDeleteBestContribution(_id)}
                                                />
                                            </Col>
                                        </PermissionWrapper>
                                    </Row>
                                ))}
                            </> : null}
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_WRITE]}>
                            <Row className="my-3 justify-content-md-center">
                                {bestContributionsWithoutDeletedInModuleIds?.length !== teamBestContributions?.length &&
                            teamBestContributions?.length > 0 ?
                                    (
                                        <Col>
                                            <Button
                                                className="cursor secondary-button lead w-100 h-100"
                                                onClick={toggleAddNewBestContributionOpened}
                                            >
                                                <strong>
                                                    <i className="bi bi-plus-circle me-2"/>
                                                    {t('content.add_new_contribution_to_graph')}
                                                </strong>
                                            </Button>
                                        </Col>
                                    ) : (
                                        <Col xs={12} className="text-center mt-2">
                                            <p>
                                                {t(`content.${teamBestContributions?.length ?
                                                    'added_all_best_contributions' :
                                                    'no_best_contributions'}`)
                                                }
                                                {!teamBestContributions?.length ?
                                                    ` ${t('content.create_new_best_contribution_first')}` :
                                                    ''
                                                }
                                            </p>
                                        </Col>
                                    )}
                                {bestContributionsWithoutDeletedInModuleIds?.length > 0 ?
                                    <Col>
                                        <Button
                                            className="cursor primary-button new-best-contribution lead w-100 h-100"
                                            onClick={() => setCreateNewMeetingModalOpened(true)}
                                        >
                                            <strong>
                                                <i className="bi bi-plus-circle me-2"/>
                                                {t('content.create_new_meeting')}
                                            </strong>
                                        </Button>
                                    </Col> : (
                                        <Col xs={12} className="text-center mt-2">
                                            <p>{t('content.need_to_add_best_contributions')}</p>
                                        </Col>
                                    )}
                            </Row>
                        </PermissionWrapper>
                    </Form>
                </div>
                <div className="previous-meetings mb-3">
                    {previousMeetings && !previousMeetings?.length &&
                        <p className="text-center">{t('content.there_are_no_previous_inputs')}</p>
                    }
                    {previousMeetings && previousMeetings?.length ? (
                        <div className="meeting my-2" onClick={toggleShowPreviousMeetings}>
                            <div className="meeting-headline px-3 py-1">
                                <span>{t('content.previous_inputs')}</span>
                                <i
                                    className={`icon-gray ${showPreviousMeetings ?
                                        'bi bi-chevron-up sort-icon' : 'bi bi-chevron-down sort-icon'}`}
                                    onClick={toggleShowPreviousMeetings}
                                />
                            </div>
                            {showPreviousMeetings &&
                                <div className="meeting-info">
                                    {previousMeetings?.map((meeting, i) => (
                                        <div key={meeting?._id} className="meeting mb-1">
                                            <div className="meeting-headline px-3 py-1" onClick={(e) => toggleMeetingInfo(e, i)}>
                                                <span style={{ color: 'grey' }}>
                                                    {reformatToISODateString(new Date(meeting?.meetingDate))}
                                                </span>
                                                <span>
                                                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_WRITE]}>
                                                        <i
                                                            className="bi bi-trash icon-gray me-2"
                                                            onClick={(e) => openAreYouSureYouWantToDeleteMeetingModal(e, meeting)}
                                                        />
                                                    </PermissionWrapper>
                                                    <i
                                                        className={`icon-gray ${meeting?.isOpenedInfo ?
                                                            'bi bi-chevron-up sort-icon' : 'bi bi-chevron-down sort-icon'}`}
                                                        onClick={(e) => toggleMeetingInfo(e, i)}
                                                    />
                                                </span>
                                            </div>
                                            {meeting?.isOpenedInfo &&
                                                <div className="meeting-info">
                                                    <Container className="align-items-center mx-1 py-3">
                                                        <Row className="text-center border-bottom h6">
                                                            <Col>{t('best_contribution.best_contribution')}</Col>
                                                            <Col>{t('content.end_goal')}</Col>
                                                            <Col>{t('content.previous_state')}</Col>
                                                            <Col>{t('content.current_state')}</Col>
                                                        </Row>
                                                        {meeting?.measurements?.map(measurment => (
                                                            <Row className="text-center" key={measurment._id}>
                                                                <Col>
                                                                    {teamBestContributionsById[measurment?.bestContribution]?.title ||
                                                                     t('content.best_contribution_deleted')}
                                                                </Col>
                                                                <Col>
                                                                    {allBestContributionsInModuleById[measurment?.bestContribution]?.endGoal
                                                                    }
                                                                </Col>
                                                                <Col>{measurment?.previousState}</Col>
                                                                <Col>{measurment?.currentState}</Col>
                                                            </Row>
                                                        ))}
                                                    </Container>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    ) : null}
                </div>
                {selectedBestContributionsDatasets?.length && previousMeetings?.length ? (
                    <div className="goals-and-metrics-chart mb-5">
                        <Chart
                            additionalOptionsProperties={{ maintainAspectRatio: false }}
                            data={{ labels: chartLabels, datasets: selectedBestContributionsDatasets }}
                            percentage={moreThanOneBestContributionOnTheGraph}
                            maxYAxis={chartYAxisMaxValue}
                        />
                    </div>
                ) : null}
                {editBestContributionModalOpened && (
                    <EditGoalsAndMetricsBestContribution
                        closeModal={toggleEditBestContributionModal}
                        bestContribution={bestContributionToEdit}
                        editBestContribution={editBestContribution}
                        validationSchema={bestContributionValidationSchema}
                    />
                )}
                {addNewBestContributionOpened && (
                    <AddBestContributionToGoalsAndMetrics
                        closeModal={toggleAddNewBestContributionOpened}
                        saveBestContribution={addBestContribution}
                        validationSchema={bestContributionValidationSchema}
                        bestContributionsInModuleIds={bestContributionsWithoutDeletedInModuleIds}
                    />
                )}
                {createNewMeetingModalOpened && (
                    <NewMeeting
                        closeModal={() => setCreateNewMeetingModalOpened(false)}
                        createMeeting={createNewMeeting}
                        chartLabels={chartLabels}
                        bestContributionsInModule={Object.values(bestContributionsWithoutDeletedInModuleById)}
                        lastMeeting={previousMeetings[0]}
                        teamBestContributionsById={teamBestContributionsById}
                        previousMeetings={previousMeetings}
                    />
                )}
                {showAreYouSureYouWantToDeleteBestContribution &&
                    <CustomModal
                        title={`${t('best_contribution.delete_best_contribution')} ${bestContributionToDelete?.title}`}
                        text1={t('best_contribution.are_you_sure_you_want_to_delete_best_contribution')}
                        warningText={t('best_contribution.best_contribution_will_be_deleted_from_goals_and_metrics')}
                        secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                        primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                        onPrimaryButtonClick={deleteBestContribution}
                        onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteBestContribution}
                    />
                }
                {showAreYouSureYouWantToDeleteMeetingModal && (
                    <CustomModal
                        title={`${t('content.delete_meeting_held')} ${reformatToISODateString(new Date(meetingToDelete?.meetingDate))}`}
                        text1={t('content.are_you_sure_you_want_to_delete_meeting')}
                        warningText={t('content.meeting_will_be_temporarily_deleted')}
                        secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                        primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                        onPrimaryButtonClick={deleteMeeting}
                        onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteMeetingModal}
                    />
                )}
            </div>
        </PermissionWrapper>
    );
}

export default GoalsAndMetrics;
