import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCopy } from '@fortawesome/free-solid-svg-icons';

import { showToastErrorMessage, showToastMessage, showToastSuccessMessage } from '../../../../features/toastSlice';
import { axios } from '../../../../helpers/apiHelper';
import PermissionWrapper from '../../../permissionWrapper';
import { REGEX, ROLES_PERMISSIONS, EXTENSIONS_ICONS, FALLBACK_EXTENSION_LINKS } from '../../../../constants';
import SortableTable from '../../../administration/sortableTable';
import { openLinkInNewTab } from '../../../../helpers/generalHelpers';
import EditLink from './editLinkModal';
import CustomModal from '../../modalCustom';

function ExternalLinksAndDocuments({ module }) {
    const fallbackExtension = FALLBACK_EXTENSION_LINKS;
    const { t } = useTranslation();
    const [links, setLinks] = useState([]);
    const [linksFetched, setLinksFetched] = useState(false);
    const [showEditLinkModal, setShowEditLinkModal] = useState(false);
    const [showAreYouSureYouWantToDeleteLink, setShowAreYouSureYouWantToDeleteLink] = useState(false);
    const [linkToDelete, setLinkToDelete] = useState();
    const [linkToEdit, setLinkToEdit] = useState();
    const teamId = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?._id);
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(t('error_message.required_field')),
        URL: Yup.string().required(t('error_message.required_field')).matches(REGEX.URL, { message: t('error_message.url_not_valid') }),
        extension: Yup.string().required(t('error_message.required_field'))
    });
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });
    const watchURL = watch('URL');
    const watchExtension = watch('extension');

    const convertDate = (timestamp) => {
        if (!timestamp) {
            return '';
        }

        const date = new Date(timestamp);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    };

    const toggleEditLinkModal = (link) => {
        setLinkToEdit(link);
        setShowEditLinkModal(prevState => !prevState);
    };

    const copyToClipBoard = (text) => {
        dispatch(showToastSuccessMessage(t('content.copied_to_clipboard')));
        navigator.clipboard.writeText(text);
    };

    const toggleAreYouSureYouWantToDeleteLinkModal = (link) => {
        setShowAreYouSureYouWantToDeleteLink(prevState => !prevState);
        setLinkToDelete(link);
    };

    const linksTableColumns = [
        { key: 'title', sortable: true },
        { key: 'displayURL' },
        { key: 'createdAt', sortable: true, type: 'date', formatDisplay: convertDate },
        {
            key: 'edit',
            icon: 'bi bi-pencil-square',
            restrictedAccess: ROLES_PERMISSIONS.DOCUMENT_WRITE,
            onClick: toggleEditLinkModal
        },
        {
            key: 'delete',
            icon: 'bi bi-trash',
            disabledKey: 'disabledDeleting',
            restrictedAccess: ROLES_PERMISSIONS.DOCUMENT_WRITE,
            onClick: toggleAreYouSureYouWantToDeleteLinkModal
        }
    ];

    const fetchLinks = async () => {
        setLinksFetched(false);
        try {
            const linksRes = await axios.get(`/document?moduleId=${module?._id}`);
            setLinks(linksRes?.data?.filter(link => link?.module === module._id)?.map(link => ({
                ...link,
                displayURL: (
                    <span className="d-flex justify-content-between align-items-center w-100 flex-no-wrap">
                        <div className="d-flex flex-no-wrap  align-items-center ">
                            <FontAwesomeIcon
                                icon={EXTENSIONS_ICONS[link?.extension || fallbackExtension]}
                                className="icon-primary align-middle pe-1"
                            />
                            <span className="align-middle pe-2 d-inline-block text-truncate" style={{ maxWidth: '25vw' }}>{link.URL}</span>
                        </div>
                        <div className="d-flex flex-no-wrap">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {t('content.copy_to_clipboard')}
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    className="icon-gray align-middle pe-2"
                                    onClick={() => copyToClipBoard(link.URL)}
                                />
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {t('content.open_in_new_tab')}
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faArrowUpRightFromSquare}
                                    className="icon-gray align-middle pe-1"
                                    onClick={() => { openLinkInNewTab(link.URL); }}
                                />
                            </OverlayTrigger>
                        </div>
                    </span>)
            })));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.dashboard.failed_loading_data')));
        }
        setLinksFetched(true);
    };

    useEffect(() => {
        (async () => {
            await fetchLinks();
        })();
    }, [module, teamId]);

    const getFileExtensionFromURL = (url) => {
        try {
            const extension = url.match(/\.([^./?#]+)($|\?|#)/)[1];
            return EXTENSIONS_ICONS[extension] ? extension : fallbackExtension;
        } catch (e) {
            return fallbackExtension;
        }
    };

    useEffect(() => {
        if (watchURL) {
            const extensionFromURL = getFileExtensionFromURL(watchURL);
            setValue('extension', extensionFromURL);
        }
    }, [watchURL]);

    const createLink = async () => {
        const linkData = getValues();
        try {
            await axios.post('/document', { documentData: {
                ...linkData,
                module: module._id,
                team: teamId
            } });
            reset();
            await fetchLinks();
            dispatch(showToastSuccessMessage(t('success_message.content.data_saved')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.content.saving_data_failed')));
        }
    };

    const editLink = async (isDirty, editedLink) => {
        if (isDirty) {
            try {
                await axios.put(`/document/${linkToEdit._id}`, { documentData: editedLink });
                fetchLinks();
                toggleEditLinkModal();
                dispatch(showToastSuccessMessage(t('success_message.content.successfully_updated_data')));
            } catch (error) {
                dispatch(showToastErrorMessage(t('error_message.administration.failed_updating_data')));
            }
        } else {
            dispatch(showToastMessage({ type: 'warning', message: t('warning_message.no_changes_made') }));
        }
    };

    const deleteLink = async () => {
        try {
            await axios.delete(`document/${linkToDelete?._id}`);
            fetchLinks();
            toggleAreYouSureYouWantToDeleteLinkModal();
            dispatch(showToastSuccessMessage(t('success_message.content.link_successfully_deleted')));
        } catch (error) {
            dispatch(showToastErrorMessage(t('error_message.content.deleting_link_failed')));
        }
    };

    return (
        <div className="scrollable-module link-container pb-5">
            <PermissionWrapper allowed={[ROLES_PERMISSIONS.DOCUMENT_READ]} displayNoAccessMessage>
                <h2 className="mb-4">{module?.title}</h2>
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.DOCUMENT_WRITE]}>
                    <h5 className="my-2">{t('content.create_link')}</h5>
                    <Form className="w-100">
                        <Row className="w-100 align-items-start">
                            <Col className="d-none d-lg-block" lg={3}>
                                <Form.Label>
                                    {t('content.title')}
                                </Form.Label>
                            </Col>
                            <Col className="d-none d-lg-block" lg={4}>
                                <Form.Label>
                                    {t('content.displayURL')}
                                </Form.Label>
                            </Col>
                            <Col className="d-none d-lg-block" lg="auto">
                                <Form.Label>
                                    {t('content.select_type_of_document')}
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row className="w-100 justify-content-between align-items-start">
                            <Col xs={12} md={7} lg={3}>
                                <Form.Group className="w-100 mb-1">
                                    <Form.Control
                                        className="removed-shadow"
                                        name="title"
                                        placeholder={t('content.title_placeholder')}
                                        {...register('title')}
                                        error={errors.title?.message}
                                    />
                                    {errors.title?.message &&
                                        <Form.Label className="error-placeholder">{errors.title?.message}</Form.Label>
                                    }
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={7} lg={4}>
                                <Form.Group className="w-100 mb-1">
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1">
                                            <FontAwesomeIcon
                                                icon={EXTENSIONS_ICONS[watchExtension || fallbackExtension]}
                                                className="icon-primary"
                                            />
                                        </InputGroup.Text>
                                        <Form.Control
                                            className="removed-shadow"
                                            name="URL"
                                            placeholder={t('content.URL_placeholder')}
                                            {...register('URL')}
                                            error={errors.URL?.message}
                                        />
                                    </InputGroup>
                                    {errors.URL?.message && <Form.Label className="error-placeholder">{errors.URL?.message}</Form.Label>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={7} lg={3}>
                                <Form.Select
                                    className="removed-shadow w-100 mb-1"
                                    isInvalid={errors?.extension}
                                    placeholder={t('content.select_type_of_document')}
                                    name="extension"
                                    defaultValue={fallbackExtension}
                                    {...register('extension', { value: fallbackExtension })}
                                >
                                    {Object.keys(EXTENSIONS_ICONS).map((extension, i) => (
                                        <option key={i} value={extension}>{extension}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs={12} md={7} lg={2}>
                                <Button
                                    type="submit"
                                    className="primary-button w-100 mb-1"
                                    onClick={handleSubmit(createLink)}
                                >
                                    {t('content.save_link')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </PermissionWrapper>
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.DOCUMENT_READ]}>
                    <div className="d-flex flex-column align-items-start my-5">
                        {linksFetched && links?.length > 0 &&
                            <SortableTable rows={links} columns={linksTableColumns} translationPath="content"/>
                        }
                        {linksFetched && links?.length === 0 && <p>{t('content.no_links')}</p>}
                        {!linksFetched && <Spinner animation="border"/>}
                    </div>
                </PermissionWrapper>
            </PermissionWrapper>
            {showEditLinkModal && (
                <EditLink
                    link={linkToEdit}
                    editLink={editLink}
                    closeModal={toggleEditLinkModal}
                    extensionIcons={EXTENSIONS_ICONS}
                    validationSchema={validationSchema}
                    fallbackExtension={fallbackExtension}
                    getFileExtensionFromURL={getFileExtensionFromURL}
                />
            )}
            {showAreYouSureYouWantToDeleteLink && (
                <CustomModal
                    title={`${t('content.delete_link')}: ${linkToDelete?.title}`}
                    text1={t('content.are_you_sure_you_want_to_delete_link')}
                    secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                    primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                    onPrimaryButtonClick={deleteLink}
                    onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteLinkModal}
                />
            )}
        </div>
    );
}

export default ExternalLinksAndDocuments;
