import { configureStore } from '@reduxjs/toolkit';

import agendaReducer from './features/agendaSlice';
import toastReducer from './features/toastSlice';
import actionsReducer from './features/actionsSlice';

export const store = configureStore({
    reducer: {
        agenda: agendaReducer,
        toast: toastReducer,
        actions: actionsReducer
    }
});