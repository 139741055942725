import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROLES_PERMISSIONS } from '../../../constants';
import { handleSwitchingLastSelectedTeam, logout } from '../../../features/agendaSlice';
import { axios } from '../../../helpers/apiHelper';
import i18n from '../../../i18n';
import PermissionWrapper from '../../permissionWrapper';
import { AccountDefaultImage } from '../../../assets/images';
import { showToastErrorMessage } from '../../../features/toastSlice';

function UserDropdown() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userData } = useSelector((state) => state.agenda);
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [showTeamMenu, setShowTeamMenu] = useState(false);
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        window.dispatchEvent(new Event('storage'));
    };

    const switchTeam = async (teamId) => {
        try {
            await axios.put('user/switchTeam', { teamId });
            await dispatch(handleSwitchingLastSelectedTeam());
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    return (
        <Dropdown className="user-dropdown">
            <Dropdown.Toggle variant="success" className="toggle user-button me-3 justify-content-end" id="dropdown-basic">
                <div className="user-info text-end d-none d-md-block">
                    <h5 className="mb-0">{`${userData?.firstName} ${userData?.lastName}`}</h5>
                    <span className="user-team">{userData?.lastSelectedTeam?.name}</span>
                </div>
                <img src={userData?.lastSelectedTeam?.company?.avatar ?? AccountDefaultImage} alt="avatar" className="avatar"/>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown-menu dropdown-menu-end" style={{ zIndex: 9999 }}>
                <Dropdown.Item className="no-hover pl-3">{`${userData?.firstName} ${userData?.lastName}`}</Dropdown.Item>
                {userData?.teams?.length > 1 || (userData?.teams?.length > 0 && !userData?.lastSelectedTeam) ?
                    <Dropdown
                        drop="start"
                        onMouseLeave={() => setShowTeamMenu(false)}
                        onMouseOver={() => setShowTeamMenu(true)}
                    >
                        <Dropdown.Toggle className="toggle dropdown-menu-toggle team-toggle">
                            {userData?.lastSelectedTeam?.name ?
                                `${t('header.switch_team')}${' ('}${userData?.lastSelectedTeam?.name}${')'}` :
                                t('dashboard_shared.choose_team')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="team-dropdown" show={showTeamMenu}>
                            {userData?.teams.map((team) => team?._id !== userData?.lastSelectedTeam?._id &&
                                <Dropdown.Item className="dropdown-item" key={team?._id} onClick={() => switchTeam(team?._id)}>
                                    {team.name}
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown> :
                    <Dropdown.Item className="no-hover">
                        {userData?.lastSelectedTeam?.name || t('administration.no_teams_available')}
                    </Dropdown.Item>
                }
                <Dropdown.Divider/>
                <Dropdown
                    drop="start"
                    onMouseLeave={() => setShowAdminMenu(false)}
                    onMouseOver={() => setShowAdminMenu(true)}
                >
                    <Dropdown.Toggle className="toggle dropdown-menu-toggle">
                        {t('administration.administration')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={showAdminMenu}>
                        <Dropdown.Item className="dropdown-item" as="button" onClick={() => navigate('/administration/users')}>
                            {t('header.users')}
                        </Dropdown.Item>
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.TEAM_WRITE]}>
                            <Dropdown.Item className="dropdown-item" as="button" onClick={() => navigate('/administration/teams')}>
                                {t('header.teams')}
                            </Dropdown.Item>
                        </PermissionWrapper>
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.COMPANY_WRITE]}>
                            <Dropdown.Item className="dropdown-item" as="button" onClick={() => navigate('/administration/companies')}>
                                {t('header.companies')}
                            </Dropdown.Item>
                        </PermissionWrapper>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                    drop="start"
                    onMouseLeave={() => setShowLanguageMenu(false)}
                    onMouseOver={() => setShowLanguageMenu(true)}
                >
                    <Dropdown.Toggle className="toggle dropdown-menu-toggle ">
                        {t('header.language')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={showLanguageMenu}>
                        <Dropdown.Item disabled={i18n.language === 'en'} className="dropdown-item" onClick={() => changeLanguage('en')}>
                            {t('header.english')}
                        </Dropdown.Item>
                        <Dropdown.Item disabled={i18n.language === 'se'} className="dropdown-item" onClick={() => changeLanguage('se')}>
                            {t('header.swedish')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown.Item className="dropdown-item py-0" href={`mailto:${process.env.REACT_APP_EMAIL_FOR_USERS_SUPPORT}`}>
                    {t('dashboard_shared.help')}
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item py-0" onClick={() => dispatch(logout())}>
                    {t('header.logout')}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default UserDropdown;
