/* eslint-disable no-unused-vars */
import { COLORS } from '../constants';

export const calculateColor = (areaOfImprovementTasks) => {
    const counts = {
        0: 0,
        1: 0,
        2: 0,
        3: 0
    };

    const colors = {
        0: COLORS.NOT_STARTED,
        1: COLORS.IN_PROGRESS,
        2: COLORS.DELAYED,
        3: COLORS.DONE
    };

    areaOfImprovementTasks.forEach((task) => { counts[task?.status || 0] += 1; });

    const sortedCountsPairs = Object.entries(counts).sort(([aKey, aCount], [bKey, bCount]) => (aCount < bCount ? 1 : -1));

    return colors[sortedCountsPairs[0][0]];
};

export const shadeColor = (color, percent) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100, 10);
    G = parseInt((G * (100 + percent)) / 100, 10);
    B = parseInt((B * (100 + percent)) / 100, 10);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
    const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
    const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));

    return `#${RR}${GG}${BB}`;
};
