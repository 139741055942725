import { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { API_URL } from '../constants';
import { checkIfUserAuthorized } from '../helpers/authHelper';

const SignIn = function () {
    const [hasUserVerificationFailed, setHasUserVerificationFailed] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (await checkIfUserAuthorized()) {
                navigate('/');
            } else {
                setHasUserVerificationFailed(true);
            }
        })();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const credentialsErrorMessage = t('error_message.sign_in.invalid_credentials');

        if (email === '' || password === '') {
            setErrorMessage(credentialsErrorMessage);
            return;
        }

        try {
            const userData = await axios.post(`${API_URL}/auth/login`, { email, password });

            if (userData.data) {
                localStorage.setItem('user', JSON.stringify(userData.data.user));
                localStorage.setItem('x-access-token', userData.data.token);
                navigate('/');
                return;
            }
        } catch (error) {
            setErrorMessage(credentialsErrorMessage);
        }
    };

    return (
        hasUserVerificationFailed &&
            <div className="container h-100 d-flex flex-row align-items-center justify-content-center">
                <Form className="col-md-6 form-wrapper" id="signInForm">
                    {errorMessage &&
                        <p className="credentials-error">
                            <i className="bi bi-exclamation-circle"> </i>
                            {errorMessage}
                        </p>
                    }
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label className="mb-0">{t('sign_up.email')}</Form.Label>
                        <Form.Control
                            name="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="password">
                        <Form.Label className="mb-0">{t('sign_up.password')}</Form.Label>
                        <Form.Control
                            name="password"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </Form.Group>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <Button
                            variant="link"
                            className="link my-2 shadow-none"
                            onClick={() => navigate('/forgot-password')}
                        >
                            {t('sign_up.forgot_your_password')}
                        </Button>
                        <Button type="submit" className="primary-button w-75 sign-button" onClick={handleSubmit}>
                            {t('sign_up.login')}
                        </Button>
                    </div>
                </Form>
            </div>
    );
};

export default SignIn;
