import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { LANGUAGES, DEFAULT_LANGUAGE } from './constants';

const fallbackLng = [DEFAULT_LANGUAGE];
const supportedLngs = [LANGUAGES.ENGLISH, LANGUAGES.SWEDISH];

const i18Options = {
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: false
    },
    fallbackLng,
    supportedLngs,
    initImmediate: false
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(i18Options);

export default i18n;