import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchSections } from '../../features/agendaSlice';
import Sidebar from './sidebar';
import { showToastErrorMessage } from '../../features/toastSlice';
import ComponentLoader from './content/componentLoader';

function Dashboard() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const lastSelectedTeam = useSelector(state => state.agenda.userData?.lastSelectedTeam);
    const agendaOpenedOnMobileScreens = useSelector((state => state.agenda.agendaOpenedOnMobileScreens));

    useEffect(() => {
        if (lastSelectedTeam?._id) {
            (async () => {
                try {
                    await dispatch(fetchSections());
                    setLoaded(true);
                } catch (error) {
                    dispatch(showToastErrorMessage(t('error_message.dashboard.failed_users_data_loading')));
                }
            })();
        }
    }, [lastSelectedTeam]);

    return (
        <>
            {loaded && lastSelectedTeam?._id &&
                <div className="home-container d-flex">
                    <div className="d-none d-sm-block"><Sidebar/></div>
                    {agendaOpenedOnMobileScreens && <div className="d-sm-none"><Sidebar/></div>}
                    <ComponentLoader/>
                </div>
            }
            {!loaded && lastSelectedTeam?._id &&
                <div className="d-flex justify-content-center m-5 loader-wrapper">
                    <div className="spinner-grow text-info loader" role="status"/>
                    <span className="loader-text">{t('dashboard_shared.loading')}</span>
                </div>
            }
            {!loaded && !lastSelectedTeam?._id &&
                <h5 className="m-5">{t('administration.no_team_selected')}</h5>
            }
        </>
    );
}

export default Dashboard;