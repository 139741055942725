import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { axios } from '../../../../helpers/apiHelper';
import {
    editBestContributionToggle,
    fetchBestContributions
} from '../../../../features/actionsSlice';
import { calculateColor } from '../../../../helpers/cssHelper';
import CustomModal from '../../modalCustom';
import { showToastErrorMessage } from '../../../../features/toastSlice';

function EditBestContribution({ moduleId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [bestContributionData, setBestContributionData] = useState();
    const [errorMessage, setErrorMessages] = useState(false);
    const { showBestContributionForm, bestContribution } = useSelector(state => state.actions);
    const teamData = useSelector((state) => state.agenda?.userData?.lastSelectedTeam);
    const [showCheckBeforeDeleteAreaOfImprovementModal, setShowCheckBeforeDeleteAreaOfImprovementModal] = useState(false);
    const [areaOfImprovementToDelete, setAreaOfImprovementToDelete] = useState();

    useEffect(() => {
        setErrorMessages(false);

        if (Object.keys(bestContribution).length) {
            setBestContributionData(bestContribution);
            return;
        }

        setBestContributionData({
            module: moduleId,
            team: teamData?._id
        });
    }, [bestContribution]);

    const handleBestContribution = (value, field) => {
        const updatedBestContributionData = { ...bestContributionData };
        updatedBestContributionData[field] = value || null;
        setBestContributionData(updatedBestContributionData);
    };

    const deleteAreaOfImprovement = async (areaOfImprovementId) => {
        try {
            await axios.put(
                `/bestContribution/${bestContributionData?._id}/removeAreaOfImprovement`,
                { areaOfImprovementId }
            );

            const updatedBestContributionData = JSON.parse(JSON.stringify(bestContributionData));

            updatedBestContributionData.areasOfImprovement = updatedBestContributionData.areasOfImprovement.filter(
                areaOfImprovement => areaOfImprovement._id !== areaOfImprovementId
            );

            setBestContributionData(updatedBestContributionData);
            setAreaOfImprovementToDelete();
            await dispatch(fetchBestContributions({ moduleId }));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    const openModalBeforeDeletingAreaOfImprovement = (areaOfImprovementId, areaOfImprovementIndex) => {
        if (bestContributionData?.areasOfImprovement[areaOfImprovementIndex]?.tasks?.length) {
            setAreaOfImprovementToDelete(areaOfImprovementId);
            setShowCheckBeforeDeleteAreaOfImprovementModal(true);
        } else {
            deleteAreaOfImprovement(areaOfImprovementId);
        }
    };

    const save = async () => {
        if (!bestContributionData?.title) {
            setErrorMessages(true);
            return;
        }

        const { areasOfImprovement, tasks, ...bestContributionDataWithoutTasks } = bestContributionData;

        try {
            if (bestContributionData?._id) {
                await axios.put(
                    `/bestContribution/${bestContributionData?._id}`,
                    { bestContributionData: bestContributionDataWithoutTasks }
                );
            } else {
                await axios.post(
                    '/bestContribution/',
                    { bestContributionData: bestContributionDataWithoutTasks }
                );
            }

            await dispatch(fetchBestContributions({ moduleId }));
            dispatch(editBestContributionToggle({ isOpened: false, bestContribution: {} }));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    return (
        <div className={`edit-best-contribution ${showBestContributionForm && 'show'}`}>
            <Form className="col-md-12">
                <h3>
                    {t(bestContributionData?._id ?
                        'best_contribution.edit_best_contribution' :
                        'best_contribution.create_best_contribution')
                    }
                </h3>
                <Form.Group className="mt-4">
                    <Form.Label className="mb-0">{t('best_contribution.title')}</Form.Label>
                    <Form.Control
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('best_contribution.title')}
                        name="title"
                        value={bestContributionData?.title || ''}
                        onChange={(event) => handleBestContribution(event.target.value, 'title')}
                    />
                    {errorMessage &&
                        <div className="errorMessage">
                            {t('error_message.edit_task.title_field_error')}
                        </div>
                    }
                </Form.Group>
                <Form.Group className="mt-4">
                    <Form.Label className="mb-0">{t('best_contribution.current_state')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('best_contribution.current_state')}
                        name="startingPoint"
                        value={bestContributionData?.startingPoint || ''}
                        onChange={(event) => handleBestContribution(event.target.value, 'startingPoint')}
                    />
                </Form.Group>
                <Form.Group className="mt-4">
                    <Form.Label className="mb-0">{t('best_contribution.desired_state')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        className="form-control mb-3 border-color-primary shadow-none"
                        placeholder={t('best_contribution.desired_state')}
                        name="desiredState"
                        value={bestContributionData?.desiredState || ''}
                        onChange={(event) => handleBestContribution(event.target.value, 'desiredState')}
                    />
                </Form.Group>
                <Form.Group className="mt-4 mb-4">
                    <Form.Label className="d-flex">
                        {t('best_contribution.completion_rate')}
                        <h5 className="ms-2">{`${bestContributionData?.completionRate || 0}%`}</h5>
                    </Form.Label>
                    <div className="d-flex justify-content-between">
                        0%
                        <Form.Range
                            className="completion-rate"
                            value={bestContributionData?.completionRate || 0}
                            onChange={(event) => handleBestContribution(event.target.value, 'completionRate')}
                        />
                        100%
                    </div>
                </Form.Group>
                {!!bestContributionData?.areasOfImprovement?.length &&
                    <div className="mb-5">
                        <h4 className="mb-4 mt-5">{t('content.areas_of_improvement')}</h4>
                        <div className="areas-of-improvement">
                            {bestContributionData?.areasOfImprovement?.map((areaOfImprovement, i) => (
                                <div
                                    key={areaOfImprovement._id}
                                    className="area-of-improvement d-flex justify-content-between"
                                    style={{
                                        backgroundColor: areaOfImprovement?.tasks?.length ?
                                            calculateColor(areaOfImprovement.tasks) :
                                            'lightgrey',
                                        color: !areaOfImprovement?.tasks?.length && 'black'
                                    }}
                                >
                                    {areaOfImprovement?.title}
                                    <i
                                        className="bi bi-x-circle cursor"
                                        onClick={() => openModalBeforeDeletingAreaOfImprovement(areaOfImprovement._id, i)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </Form>
            {showCheckBeforeDeleteAreaOfImprovementModal && areaOfImprovementToDelete && (
                <CustomModal
                    title={t('best_contribution.delete_area_of_improvement.title')}
                    text1={t('best_contribution.delete_area_of_improvement.are_you_sure')}
                    warningText={t('best_contribution.delete_area_of_improvement.after_deleting_tasks_NAOI')}
                    secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                    primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                    onPrimaryButtonClick={() => { deleteAreaOfImprovement(areaOfImprovementToDelete); }}
                    onSecondaryButtonClick={() => { setShowCheckBeforeDeleteAreaOfImprovementModal(false); }}
                />
            )}
            <div className="d-flex justify-content-center buttons" style={{ justifySelf: 'flex-end' }}>
                <button
                    type="button"
                    className="primary-button w-25 me-4 button"
                    onClick={() => dispatch(editBestContributionToggle({ isOpened: false, bestContribution: {} }))}
                >
                    {t('dashboard_shared.close')}
                </button>
                <button
                    type="button"
                    className="primary-button w-75 ms-1 button save"
                    onClick={save}
                >
                    {t('content.save')}
                </button>
            </div>
        </div>
    );
}

export default EditBestContribution;
