import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AGENDA_MODULE_TYPES, MODULE_TYPES, ROLES_PERMISSIONS } from '../../../constants';
import ModuleTypes from './moduleTypes';
import {
    editModuleToggle,
    saveModule,
    moveUpModule,
    moveDownModule,
    deleteModule,
    fetchSections
} from '../../../features/agendaSlice';
import PermissionWrapper from '../../permissionWrapper';
import { showToastErrorMessage } from '../../../features/toastSlice';
import CustomModal from '../modalCustom';

function Module({ sectionIndex, moduleIndex }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { modules } = useSelector((state) => state.agenda?.sections[sectionIndex]);
    const { editModule } = useSelector((state) => state.agenda);
    const [module, setModule] = useState({});
    const [showAreYouSureYouWantToDeleteModuleModal, setShowAreYouSureYouWantToDeleteModuleModal] = useState(false);

    useEffect(() => {
        setModule(modules[moduleIndex]);
    }, [modules, moduleIndex]);

    const update = (value, prop) => {
        const updatedModule = { ...module };
        updatedModule[prop] = value;
        setModule(updatedModule);
    };

    const save = async () => {
        await dispatch(saveModule({ sectionIndex, moduleIndex, module }));
        dispatch(editModuleToggle({ sectionIndex, moduleIndex, isOpened: false }));
    };

    const cancel = () => {
        setModule(modules[moduleIndex]);
        dispatch(editModuleToggle({ sectionIndex, moduleIndex, isOpened: false }));
    };

    const toggleAreYouSureYouWantToDeleteModuleModal = () => {
        setShowAreYouSureYouWantToDeleteModuleModal(prevState => !prevState);
    };

    const removeModule = async () => {
        const { _id, type } = modules[moduleIndex];
        if (type.toString() === MODULE_TYPES.TEXT.toString()) {
            dispatch(showToastErrorMessage(t('error_message.agenda.cant_delete_module')));
        } else {
            await dispatch(deleteModule({ _id }));
            await dispatch(fetchSections());
        }
        toggleAreYouSureYouWantToDeleteModuleModal();
    };

    const moveUp = async () => {
        const modulesToReorder = JSON.parse(JSON.stringify(modules));
        const moduleUp = modulesToReorder[moduleIndex];
        const moduleDown = modulesToReorder[moduleIndex - 1];
        moduleUp.order -= 1;
        moduleDown.order += 1;

        await dispatch(moveUpModule({ modules: [moduleUp, moduleDown], sectionIndex, moduleIndex }));
    };

    const moveDown = async () => {
        const modulesToReorder = JSON.parse(JSON.stringify(modules));
        const moduleDown = modulesToReorder[moduleIndex];
        const moduleUp = modulesToReorder[moduleIndex + 1];
        moduleDown.order += 1;
        moduleUp.order -= 1;

        await dispatch(moveDownModule({ modules: [moduleUp, moduleDown], sectionIndex, moduleIndex }));
    };

    return (
        <div className="border p-1 ms-2 mb-2 rounded">
            {editModule[sectionIndex]?.[moduleIndex] ?
                <div className="agenda-module-edit">
                    <Form.Control
                        className="input border-color-primary shadow-none"
                        defaultValue={module?.title}
                        onChange={(event) => update(event.target.value, 'title')}
                    />
                    <select
                        className="select border-color-primary shadow-nonet"
                        defaultValue={module?.type}
                        disabled
                    >
                        <option>{t('agenda.select_module_type')}</option>
                        <ModuleTypes/>
                    </select>
                    <h5 className="m-0">
                        <i className="bi bi-check-square cursor ms-2 visible-icon" onClick={save}/>
                        <i className="bi bi-x-square cursor ms-2 visible-icon" onClick={cancel}/>
                    </h5>
                </div> :
                <div className="d-flex hover-area agenda-module">
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                        <div className="sorting-arrows me-2">
                            {
                                (modules[moduleIndex]?.order !== 1) &&
                                    <i className="bi bi-caret-up-fill icon" onClick={moveUp}/>
                            }
                            {
                                (modules[moduleIndex]?.order !== modules.length) &&
                                    <i className="bi bi-caret-down-fill icon" onClick={moveDown}/>
                            }
                        </div>
                    </PermissionWrapper>
                    <span>{`${module?.title} -`}</span>
                    <span className="text-secondary ps-2">{t(`agenda.${AGENDA_MODULE_TYPES[module?.type]}`)}</span>
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                        <i
                            className="bi bi-pencil-square ms-2 cursor icon visible-icon"
                            onClick={() => dispatch(editModuleToggle({ sectionIndex, moduleIndex, isOpened: true }))}
                        />
                        {module?.type?.toString() !== MODULE_TYPES.TEXT.toString() &&
                            <i className="bi bi-trash m-2 cursor icon visible-icon" onClick={toggleAreYouSureYouWantToDeleteModuleModal}/>}
                    </PermissionWrapper>
                    {showAreYouSureYouWantToDeleteModuleModal && (
                        <CustomModal
                            title={`${t('agenda.delete_module')}: ${module?.title}`}
                            text1={t('agenda.are_you_sure_you_want_to_delete_module')}
                            warningText={t('agenda.module_will_be_temporarily_deleted')}
                            secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                            primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                            onPrimaryButtonClick={removeModule}
                            onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteModuleModal}
                        />
                    )}
                </div>
            }
        </div>
    );
}

export default Module;

