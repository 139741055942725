import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { COLORS } from '../../../constants';
import { shadeColor } from '../../../helpers/cssHelper';
import UserDropdown from './userDropdown';
import { setAgendaOpenedOnMobileScreen } from '../../../features/agendaSlice';

function Header() {
    const company = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?.company);
    const agendaOpenedOnMobileScreens = useSelector((state => state.agenda.agendaOpenedOnMobileScreens));
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const administrationPageIsOpened = location.pathname.split('/')[1] === 'administration';

    useEffect(() => {
        if (company) {
            const r = document.querySelector(':root');
            r.style.setProperty('--primary-rgb', company?.color || COLORS.PRIMARY_COLOR);
            r.style.setProperty('--secondary-rgb', shadeColor(company?.color || COLORS.PRIMARY_COLOR, -20));
        }
    }, [company]);

    return (
        <div className="header" style={{ backgroundColor: company?.color }}>
            <div className="logo-section">
                {!administrationPageIsOpened && <FontAwesomeIcon
                    className={`d-sm-none me-2 ${agendaOpenedOnMobileScreens ? 'rotate' : ''}`}
                    icon={faBars}
                    onClick={() => dispatch(setAgendaOpenedOnMobileScreen(!agendaOpenedOnMobileScreens))}
                />}
                {company?.logo &&
                    <img
                        onClick={() => navigate('/')}
                        src={company?.logo}
                        alt="logo"
                        className="logo cursor d-none d-sm-block"
                    />
                }
                <span
                    className="dashboard-title cursor"
                    onClick={() => {
                        navigate('/');
                        dispatch(setAgendaOpenedOnMobileScreen(false));
                    }}
                >
                    {t('header.title')}
                </span>
            </div>
            {!agendaOpenedOnMobileScreens && <UserDropdown/>}
        </div>
    );
}

export default Header;
