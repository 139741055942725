import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODULE_TYPES, ROLES_PERMISSIONS } from '../../../../constants';
import { showToastErrorMessage } from '../../../../features/toastSlice';
import { axios } from '../../../../helpers/apiHelper';
import { reformatDate } from '../../../../helpers/generalHelpers';
import PermissionWrapper from '../../../permissionWrapper';
import Chart from '../goalsAndMetrics/barChart';

function GoalsAndMetricsGraph() {
    const [teamBestContributionsById, setTeamBestContributionsById] = useState({});
    const [moduleData, setModuleData] = useState();
    const [chartLabels, setChartLabels] = useState([]);
    const [allBestContributionsDatasets, setAllBestContributionsDatasets] = useState([]);
    // endgoal and other values are not changeable
    const [bestContributionsWithoutDeletedInModuleById, setBestContributionsWithoutDeletedInModuleById] = useState({});
    // including deleted best contributions
    const [goalsAndMetricsModuleId, setGoalsAndMetricsModuleId] = useState();
    const { teamBestContributions } = useSelector((state) => state.actions);
    const { sections } = useSelector((state) => state.agenda);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        let moduleFound = false;
        sections?.map((section) => section?.modules?.forEach((module) => {
            if (module.type === MODULE_TYPES.GOALS_AND_METRICS) {
                setGoalsAndMetricsModuleId(module?._id);
                moduleFound = true;
            }
        }));
        if (!moduleFound) {
            setGoalsAndMetricsModuleId();
        }
    }, [sections]);

    const fetchModule = async () => {
        if (goalsAndMetricsModuleId) {
            try {
                const moduleRes = await axios.get(`goalsAndMetrics?moduleId=${goalsAndMetricsModuleId}`);
                setModuleData(moduleRes?.data);
                setChartLabels(moduleRes?.data?.meetings?.map((meet) => reformatDate((new Date(meet.meetingDate)))).reverse() || []);
            } catch (err) {
                dispatch(showToastErrorMessage('error_message.dashboard.failed_loading_data'));
            }
        } else {
            setModuleData({});
        }
    };

    useEffect(() => {
        (async () => fetchModule())();
    }, [goalsAndMetricsModuleId]);

    useEffect(() => {
        if (teamBestContributions && moduleData) {
            const teamBestContributionsByIdTemp = teamBestContributions.reduce((acc, bc) => ({ ...acc, [bc._id]: bc }), {});
            const filteredModuleBestContributions = moduleData?.bestContributions?.filter(bc => teamBestContributionsByIdTemp[bc._id]);
            setBestContributionsWithoutDeletedInModuleById(filteredModuleBestContributions?.reduce((acc, bc) => (
                {
                    ...acc,
                    [bc._id]: bc
                }), {}));
            setTeamBestContributionsById(teamBestContributionsByIdTemp);
        }
    }, [teamBestContributions, moduleData]);

    useEffect(() => {
        if (moduleData && teamBestContributionsById && bestContributionsWithoutDeletedInModuleById) {
            const allBestContributionsDatasetsTemp = [];
            moduleData?.bestContributions?.forEach(bc => {
                if (teamBestContributionsById[bc?._id]) {
                    const datasetArray = [];

                    moduleData?.meetings?.forEach((meet) => {
                        let value = false;
                        meet?.measurements?.forEach(measurment => {
                            if (measurment?.bestContribution === bc._id) {
                                value = measurment?.currentState;
                            }
                        });
                        if (value !== false) {
                            const percentageValue = (value && ((value / bc.endGoal) * 100));
                            datasetArray.push({
                                x: reformatDate((new Date(meet.meetingDate))),
                                y: percentageValue,
                                percentageValue,
                                value,
                                label: teamBestContributionsById[bc._id]?.title || t('content.best_contribution_deleted'),
                                endGoal: bc?.endGoal
                            });
                        }
                    });

                    allBestContributionsDatasetsTemp.push(
                        {
                            bestContributionId: bc?._id,
                            label: teamBestContributionsById[bc?._id]?.title || t('content.best_contribution_deleted'),
                            data: datasetArray,
                            backgroundColor: bestContributionsWithoutDeletedInModuleById[bc?._id]?.color,
                            minBarLength: 7,
                            maxBarThickness: 50
                        }
                    );
                }
            });
            setAllBestContributionsDatasets(allBestContributionsDatasetsTemp);
        }
    }, [moduleData, teamBestContributionsById, bestContributionsWithoutDeletedInModuleById]);

    return (
        <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_READ]} displayNoAccessMessage>
            <Chart
                maxYAxis={100}
                additionalOptionsProperties={{ maintainAspectRatio: false }}
                data={{ labels: chartLabels, datasets: allBestContributionsDatasets }}
                percentage
            />
        </PermissionWrapper>
    );
}

export default GoalsAndMetricsGraph;
