import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { REGEX } from '../constants';
import { showToastErrorMessage, showToastSuccessMessage } from '../features/toastSlice';
import { axios } from '../helpers/apiHelper';

function ForgotPassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        email: Yup.string().matches(REGEX.EMAIL, { message: t('error_message.email_not_valid') })
    });
    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });

    const sendEmailForResetingPassword = async () => {
        try {
            setIsLoading(true);
            await axios.put('user/forgotPassword', { email: getValues('email') });
            setIsLoading(false);
            setEmailSent(true);
            dispatch(showToastSuccessMessage(t('success_message.sign_in.email_sent')));
        } catch (err) {
            setIsLoading(false);
            dispatch(showToastErrorMessage(t('error_message.sign_in.sending_email_failed')));
        }
    };

    return (
        <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            <div
                className="container password-container border col col-xs-10 col-lg-6 m-auto p-5"
            >
                <Button
                    variant="link"
                    className="link align-self-end shadow-none"
                    onClick={() => navigate('/sign-in')}
                >
                    {t('sign_up.go_back_to_sign_in')}
                </Button>
                <h2 className="align-self-start mt-2 mb-4">{emailSent ? 'Check your email' : 'Reset password'}</h2>
                {emailSent ?
                    <div className="w-100">
                        <p className="mt-2">
                            {t('sign_up.reset_email_sent_to')}
                            <b>{` ${getValues('email').replace(/(\w{2})[\w.-]+@([\w.]+\w)/, '$1******@$2')}. `}</b>
                            {t('sign_up.click_the_link_in_email')}
                        </p>
                        <p className="mt-4">{t('sign_up.not_seeing_email_instructions')}</p>
                    </div> :
                    <Form className="w-100" onSubmit={handleSubmit(sendEmailForResetingPassword)}>
                        <p>{t('sign_up.enter_your_email_address')}</p>
                        <Form.Group className="p-2 my-3 rounded w-100" style={{ border: '2px solid lightgray' }}>
                            <Form.Control
                                type="email"
                                name="email"
                                {...register('email')}
                                placeholder={t('administration.users_table.email')}
                                isInvalid={errors.email?.message}
                                className="form-control border-0 border-bottom shadow-none"
                            />
                            {errors.email?.message && <Form.Label className="error-placeholder">{errors.email?.message}</Form.Label>}
                        </Form.Group>
                        <Button type="submit" className="primary-button w-100 py-2">
                            {isLoading && <div className="spinner-border spinner-border-sm me-1" role="status"/>}
                            {t('sign_up.send_me_email_for_reset')}
                        </Button>
                    </Form>
                }
            </div>
        </div>
    );
}

export default ForgotPassword;