import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Button, Table } from 'react-bootstrap';

import { axios } from '../../../../helpers/apiHelper';
import {
    newAreaOfImprovementToggle,
    fetchBestContributions
} from '../../../../features/actionsSlice';
import { showToastErrorMessage } from '../../../../features/toastSlice';

function NewAreaOfImprovement() {
    const dispatch = useDispatch();
    const { bestContribution } = useSelector(state => state.actions);
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);

    const save = async () => {
        if (!isSubmitButtonPressed) {
            setIsSubmitButtonPressed(true);
        }
        if (title) {
            try {
                await axios.put(
                    `/bestContribution/${bestContribution._id}/addAreaOfImprovement`,
                    { title }
                );

                await dispatch(fetchBestContributions({ moduleId: bestContribution.module }));
                dispatch(newAreaOfImprovementToggle({ bestContribution: {} }));
            } catch (err) {
                dispatch(showToastErrorMessage(t('error_message.global_message')));
            }
        }
    };

    return (
        <div className="new-area-of-improvement">
            <Table className="full-table">
                <thead>
                    <tr>
                        <th className="m-2 table-title">
                            <Form.Group>
                                <Form.Control
                                    className="form-control border-color-primary shadow-none"
                                    placeholder={t('best_contribution.title')}
                                    name="title"
                                    autoFocus
                                    value={title || ''}
                                    onChange={(event) => setTitle(event.target.value)}
                                    isInvalid={isSubmitButtonPressed && !title}
                                />
                            </Form.Group>
                        </th>
                        <th className="m-2 table-cell">{t('best_contribution.responsible')}</th>
                        <th className="m-2 table-cell">{t('best_contribution.support_by')}</th>
                        <th className="m-2 table-cell">{t('best_contribution.deadline')}</th>
                        <th className="m-2 table-cell">{t('best_contribution.comment')}</th>
                        <th className="m-2 task-status">{t('best_contribution.status')}</th>
                    </tr>
                </thead>
            </Table>
            <div className="float-end">
                <Button
                    variant="secondary me-2"
                    onClick={() => dispatch(newAreaOfImprovementToggle({ bestContribution: {} }))}
                >
                    {t('dashboard_shared.close')}
                </Button>
                <Button
                    variant="primary"
                    className="sign-button"
                    onClick={save}
                >
                    {t('content.save')}
                </Button>
            </div>
        </div>
    );
}

export default NewAreaOfImprovement;

