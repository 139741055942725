import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { editTaskToggle, fetchBestContributions } from '../../../../features/actionsSlice';
import { calculateColor } from '../../../../helpers/cssHelper';
import { AREA_OF_IMPROVEMENT_STATUSES, ROLES_PERMISSIONS, SORT } from '../../../../constants';
import { reformatDate } from '../../../../helpers/generalHelpers';
import PermissionWrapper from '../../../permissionWrapper';
import { axios } from '../../../../helpers/apiHelper';
import CustomModal from '../../modalCustom';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../../features/toastSlice';

function BestContributionTable({
    title,
    tasks,
    bestContributionId,
    areaOfImprovementToEdit,
    setFilteredBestContributions
}) {
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.actions);
    const { t } = useTranslation();
    const indexOfNotStarted = AREA_OF_IMPROVEMENT_STATUSES.indexOf('not-started');
    const [bestContributionTasks, setBestContributionTasks] = useState([]);
    const [sortOrder, setSortOrder] = useState(SORT.ASC);
    const [taskToDelete, setTaskToDelete] = useState();
    const [showAreYouSureYouWantToDeleteTaskModal, setShowAreYouSureYouWantToDeleteTaskModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [newAreaOfImprovementTitle, setNewAreaOfImprovementTitle] = useState(title);

    useEffect(() => {
        setBestContributionTasks(tasks);
    }, [tasks]);

    const getUserName = (userId) => {
        const userData = users.find(user => user._id === userId);

        if (!userData) {
            return '';
        }

        return `${userData?.firstName} ${userData?.lastName}`;
    };

    const sortByDeadline = () => {
        const tasksToSort = [...bestContributionTasks];
        let sortedTasks;
        // min and max allowed dates to exist for actions without deadline
        const minDate = new Date((new Date(-8640000000000000)).getTime());
        const maxDate = new Date((new Date(8640000000000000)).getTime());

        if (sortOrder === SORT.ASC) {
            sortedTasks = tasksToSort.sort(
                (a, b) => ((new Date(a?.deadline || maxDate)) >= (new Date(b?.deadline || maxDate)) ? 1 : -1)
            );
            setSortOrder(SORT.DESC);
        }

        if (sortOrder === SORT.DESC) {
            sortedTasks = tasksToSort.sort(
                (a, b) => ((new Date(b?.deadline || minDate)) >= (new Date(a?.deadline || minDate)) ? 1 : -1)
            );
            setSortOrder(SORT.ASC);
        }

        setBestContributionTasks(sortedTasks);
    };

    const toggleAreYouSureYouWantToDeleteTaskModal = () => {
        setShowAreYouSureYouWantToDeleteTaskModal(prevState => !prevState);
    };

    const areYouSureYouWantToDeleteTask = (e, task) => {
        e.stopPropagation();
        setTaskToDelete(task);
        toggleAreYouSureYouWantToDeleteTaskModal();
    };

    const deleteTask = async () => {
        try {
            await axios.delete(`task/delete/${taskToDelete?._id}`);
            await dispatch(fetchBestContributions({ moduleId: taskToDelete?.module }));
            toggleAreYouSureYouWantToDeleteTaskModal();
            dispatch(showToastSuccessMessage(t('success_message.best_contribution.successfully_deleted_task')));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.best_contribution.deleting_task_failed')));
        }
    };

    const cancelAreaOfImprovementTitleEdit = () => {
        setIsEditMode(prevValue => !prevValue);
        setNewAreaOfImprovementTitle(title);
    };

    const saveNewAreaOfImprovementTitle = async () => {
        try {
            await axios.put(
                `/bestContribution/${bestContributionId}/updateAreaOfImprovementTitle`,
                {
                    areaOfImprovementData: {
                        _id: areaOfImprovementToEdit,
                        title: newAreaOfImprovementTitle
                    }
                }
            );
            setFilteredBestContributions((prevValue) => prevValue.map((bestContribution) => {
                if (!bestContribution._id === bestContributionId) {
                    return bestContribution;
                }
                const tempBestContribution = { ...bestContribution };

                const tempAreasOfImprovement = bestContribution.areasOfImprovement.map((areaOfImprovement) => {
                    const tempAreaOfImprovement = { ...areaOfImprovement };
                    if (areaOfImprovement._id === areaOfImprovementToEdit) {
                        tempAreaOfImprovement.title = newAreaOfImprovementTitle;
                    }
                    return tempAreaOfImprovement;
                });
                return { ...tempBestContribution, areasOfImprovement: tempAreasOfImprovement };
            }));
            setIsEditMode(false);
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    const updateCurrentTextareaTitleValue = (value) => {
        setNewAreaOfImprovementTitle(value);
    };

    return (
        <Table className="full-table">
            <thead>
                <tr>
                    <th
                        className="m-2 table-title"
                        style={{
                            backgroundColor: bestContributionTasks?.length ? calculateColor(bestContributionTasks) : 'lightgrey',
                            color: bestContributionTasks?.length ? 'white' : 'black'
                        }}
                    >
                        {isEditMode ?
                            <div className="d-flex">
                                <Form.Control
                                    className={`area-of-improvement-title-edit shadow-none ${
                                        !bestContributionTasks?.length && 'border-dark'}`}
                                    defaultValue={newAreaOfImprovementTitle}
                                    onChange={(event) => updateCurrentTextareaTitleValue(event.target.value)}
                                />
                                <i className="bi bi-check-square ms-2 cursor" onClick={() => saveNewAreaOfImprovementTitle()}/>
                                <i className="bi bi-x-square ms-2 cursor" onClick={() => cancelAreaOfImprovementTitleEdit()}/>
                            </div> :
                            <span style={{ display: 'inline-block', width: '100%' }}>
                                <div className="area-of-improvement-title-wrapper w-100">
                                    <div style={{ wordBreak: 'break-word' }}>
                                        {title}
                                    </div>
                                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.BEST_CONTRIBUTION_WRITE]}>
                                        { title !== t('content.no_area_of_improvement') &&
                                            <i
                                                className="bi bi-pencil-square cursor area-of-improvement-edit-title-icon"
                                                onClick={() => setIsEditMode(!isEditMode)}
                                            />}
                                    </PermissionWrapper>
                                </div>
                            </span>
                        }
                    </th>
                    <th className="m-2 table-cell">{t('best_contribution.responsible')}</th>
                    <th className="m-2 table-cell">{t('best_contribution.support_by')}</th>
                    <th className="m-2 table-cell">
                        {t('best_contribution.deadline')}
                        <i
                            className={`ms-2 cursor bi ${sortOrder === SORT.ASC ? 'bi-chevron-down' : 'bi-chevron-up'}`}
                            onClick={sortByDeadline}
                        />
                    </th>
                    <th className="m-2 table-cell">{t('best_contribution.comment')}</th>
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_WRITE]}>
                        <th className="task-delete print-hide">{t('administration.users_table.delete')}</th>
                    </PermissionWrapper>
                    <th className="m-2 task-status">{t('best_contribution.status')}</th>
                </tr>
            </thead>
            <tbody className="table-body">
                {bestContributionTasks?.length ?
                    bestContributionTasks.map(task => (
                        <tr
                            key={task?._id || Math.random()}
                            onClick={() => dispatch(editTaskToggle({ isOpened: true, task }))}
                            className="cursor"
                        >
                            <td className="m-2">
                                <i className={`
                                        bi bi-circle-fill ms-2 me-2 dot
                                        ${AREA_OF_IMPROVEMENT_STATUSES[task?.status] || AREA_OF_IMPROVEMENT_STATUSES[indexOfNotStarted]}
                                    `}
                                />
                                {task?.title}
                            </td>
                            <td className="m-2">{getUserName(task?.responsible)}</td>
                            <td className="m-2">{task?.fullTeam ? 'Full team' : getUserName(task?.supportBy)}</td>
                            <td className="m-2">{reformatDate(task?.deadline)}</td>
                            <td className="m-2">{task?.comment}</td>
                            <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_WRITE]}>
                                <td className="text-center print-hide">
                                    <i
                                        className="bi bi-trash icon-gray"
                                        onClick={(e) => areYouSureYouWantToDeleteTask(e, task)}
                                    />
                                </td>
                            </PermissionWrapper>
                            <td className={`m-2 status ${AREA_OF_IMPROVEMENT_STATUSES[task?.status] ||
                                AREA_OF_IMPROVEMENT_STATUSES[indexOfNotStarted]}`}
                            >
                                {t(`best_contribution.task_statuses.${AREA_OF_IMPROVEMENT_STATUSES[task?.status] ||
                                    AREA_OF_IMPROVEMENT_STATUSES[indexOfNotStarted]}`)}
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td className="m-2">
                                <h6 className="ms-2">{t('best_contribution.no_tasks')}</h6>
                            </td>
                        </tr>
                    )}
            </tbody>
            {showAreYouSureYouWantToDeleteTaskModal && (
                <CustomModal
                    title={`${t('best_contribution.delete_task')}: ${taskToDelete?.title}`}
                    text1={t('best_contribution.are_you_sure_you_want_to_delete_task')}
                    warningText={t('best_contribution.task_will_be_temporarily_deleted')}
                    secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                    primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                    onPrimaryButtonClick={deleteTask}
                    onSecondaryButtonClick={toggleAreYouSureYouWantToDeleteTaskModal}
                />
            )}
        </Table>
    );
}

export default BestContributionTable;
