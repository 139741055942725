import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Textbox({ textbox, backgroundColor, moduleIndex, sectionIndex }) {
    const [textboxModule, setTextboxModule] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        setTextboxModule(textbox);
    }, [textbox]);

    return (
        textboxModule && (
            <div className="textbox-dashboard text-start mb-1 px-3 h-100" style={{ backgroundColor }}>
                <div className="d-flex justify-content-between pt-2">
                    <p className="lead mb-1"><strong>{textboxModule?.module?.title}</strong></p>
                    <i
                        className="bi bi-pencil-square cursor icon-primary"
                        onClick={() => navigate(sectionIndex !== undefined && moduleIndex !== undefined ?
                            `/module/#_${sectionIndex}_${moduleIndex}` :
                            '')
                        }
                    />
                </div>
                <div className="textbox-dashboard-content">
                    {textboxModule?.description && <p><small>{textboxModule.description}</small></p>}
                </div>
            </div>
        )
    );
}

export default Textbox;
