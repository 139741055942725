import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { axios } from '../../../../helpers/apiHelper';
import Idea from './idea';
import EditTask from '../actionPlan/editTask';
import { fetchIdeas, editTaskToggle } from '../../../../features/actionsSlice';
import { showToastErrorMessage } from '../../../../features/toastSlice';

function Ideas({ module }) {
    const dispatch = useDispatch();
    const { ideas, task, showEditTask } = useSelector((state) => state.actions);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(editTaskToggle({ isOpened: false, task: {} }));
        (async () => {
            await dispatch(fetchIdeas(module._id));
        })();
    }, [module]);

    const save = async (taskData, isIdea) => {
        try {
            if (isIdea) {
            // save/edit idea/task
                taskData.isIdea = true;
                await axios.put(
                    `/task/update-idea/${task?._id}`,
                    { taskData }
                );
            } else {
            // convert idea to task
                await axios.put(
                    `/task/convert/${task?._id}`,
                    { taskData }
                );
            }

            await dispatch(fetchIdeas(module._id));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    const deleteIdea = async (idea) => {
        try {
            await axios.delete(`/task/${idea?._id}`);
            await dispatch(fetchIdeas(module?._id));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    return (
        <div className="ideas scrollable-module">
            <h2 className="pb-4">{module?.title || t('content.log_of_ideas')}</h2>
            <div className="row">
                {ideas?.map((idea, key) => <Idea key={key} idea={idea} deleteIdea={deleteIdea}/>)}
            </div>
            {ideas && ideas?.length === 0 && <p>{t('content.no_ideas')}</p>}
            {showEditTask && <EditTask save={save}/>}
        </div>
    );
}

export default Ideas;
