import Axios from 'axios';

import { API_URL } from '../constants';

export const axios = Axios.create({ baseURL: `${API_URL}` });

axios.interceptors.request.use((req) => {
    if (localStorage.getItem('x-access-token')) {
        req.headers = { 'x-access-token': localStorage.getItem('x-access-token') || null };
    }
    return req;
});

// handling token expiration
axios.interceptors.response.use(response => response, error => {
    if (error?.response?.status === 401) {
        if (window.location.pathname !== '/sign-in') {
            window.location.replace('/sign-in');
        }
    }

    return Promise.reject(error);
});
