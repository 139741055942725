import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModuleTypes from './moduleTypes';
import { cancelNewModule, saveNewModule } from '../../../features/agendaSlice';

function NewModule({ sectionIndex, modulesThatCantBeAdded }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const newModule = useSelector((state) => state.agenda?.newModule[sectionIndex]);
    const [module, setModule] = useState({});
    const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);
    const [showError, setShowError] = useState(false);
    const [titleDefined, setTitleDefined] = useState(false);
    const [moduleTypeSelected, setModuleTypeSelected] = useState(false);

    useEffect(() => {
        setModule(newModule);
    }, [newModule]);

    const update = (value, prop) => {
        const updatedModule = { ...module };
        updatedModule[prop] = value;
        setModule(updatedModule);
        if (prop === 'type') {
            setModuleTypeSelected(!!value);
        } else if (prop === 'title') {
            setTitleDefined(!!value);
        }
    };

    useEffect(() => {
        if ((!moduleTypeSelected || !titleDefined) && isSubmitButtonPressed) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [moduleTypeSelected, titleDefined, isSubmitButtonPressed]);

    const save = async () => {
        if (!isSubmitButtonPressed) {
            setIsSubmitButtonPressed(true);
        }
        if (moduleTypeSelected && titleDefined) {
            await dispatch(saveNewModule({ sectionIndex, module }));
            dispatch(cancelNewModule());
        }
    };

    return (
        <div className="agenda-module-edit ms-2">
            <Form.Control
                className="input border-color-primary shadow-none"
                defaultValue={newModule?.title}
                isInvalid={showError && !titleDefined}
                onChange={(event) => update(event.target.value, 'title')}
                placeholder={t('content.title')}
            />
            <select
                className={`${showError && !moduleTypeSelected ? 'border-danger' : ''} select`}
                value={newModule[sectionIndex]?.type}
                onChange={(event) => update(event.target.value, 'type')}
            >
                <option value="">{t('agenda.select_module_type')}</option>
                <ModuleTypes modulesThatCantBeAdded={modulesThatCantBeAdded}/>
            </select>
            <h5 className="m-0">
                <i className="bi bi-check-square cursor ms-2 visible-icon" onClick={save}/>
                <i className="bi bi-x-square cursor ms-2 visible-icon" onClick={() => dispatch(cancelNewModule())}/>
            </h5>
        </div>
    );
}

export default NewModule;

