import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axios } from '../helpers/apiHelper';
import { showToastErrorMessage } from './toastSlice';
import i18n from '../i18n';

const initialState = {
    ideas: [],
    users: [],
    showEditTask: false,
    createAction: false,
    editIdeas: false,
    task: {},
    bestContribution: {},
    moduleBestContributions: [],
    teamBestContributions: [],
    showBestContributionForm: false,
    showNewAreaOfImprovement: ''
};

export const fetchIdeas = createAsyncThunk('fetchIdeas', async (moduleId, { dispatch, rejectWithValue }) => {
    try {
        const storedIdeas = await axios.get(`/task/?moduleId=${moduleId}`);

        return storedIdeas.data;
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const fetchUsers = createAsyncThunk('fetchUsers', async (teamId, { dispatch, rejectWithValue }) => {
    try {
        const fetchedUsers = await axios.get(`/user/?teamId=${teamId}`);

        return fetchedUsers.data;
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const fetchAreasOfImprovement = createAsyncThunk('fetchAreasOfImprovement', async (
    bestContributionId,
    { dispatch, rejectWithValue }
) => {
    try {
        const fetchedAreasOfImprovement = await axios.get(`/bestContribution/${bestContributionId}`);

        return fetchedAreasOfImprovement.data;
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const fetchBestContributions = createAsyncThunk('fetchBestContributions', async (
    { moduleId, teamId },
    { dispatch, rejectWithValue }
) => {
    try {
        let fetchedBestContributions;

        if (moduleId) {
            fetchedBestContributions = await axios.get(`/bestContribution/module?moduleId=${moduleId}`);
        } else if (teamId) {
            fetchedBestContributions = await axios.get(`/bestContribution/team?teamId=${teamId}`);
        }

        return { bestContributions: fetchedBestContributions?.data, moduleId };
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        editTaskToggle: (state, action) => {
            state.showEditTask = action.payload.isOpened;
            state.task = action.payload.task;
            state.editIdeas = action.payload.editIdeas;
            state.createAction = action.payload?.createAction || false;
        },
        editBestContributionToggle: (state, action) => {
            state.showBestContributionForm = action.payload.isOpened;
            state.bestContribution = action.payload.bestContribution;
        },
        newAreaOfImprovementToggle: (state, action) => {
            state.showNewAreaOfImprovement = action.payload.bestContribution._id;
            state.bestContribution = action.payload.bestContribution;
        }
    },
    extraReducers: {
        [fetchIdeas.fulfilled]: (state, action) => {
            state.ideas = action.payload;
        },
        [fetchUsers.fulfilled]: (state, action) => {
            state.users = action.payload;
        },
        [fetchBestContributions.fulfilled]: (state, action) => {
            if (action.payload.moduleId) {
                state.moduleId = action.payload.moduleId;
                state.moduleBestContributions = action.payload?.bestContributions;
            } else {
                state.teamBestContributions = action.payload?.bestContributions;
            }
        }
    }
});

export const {
    editTaskToggle,
    editBestContributionToggle,
    newAreaOfImprovementToggle
} = actionsSlice.actions;

export default actionsSlice.reducer;