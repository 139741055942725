import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { agendaModalToggle, setAgendaOpenedOnMobileScreen } from '../../features/agendaSlice';
import Agenda from './agenda';
import PermissionWrapper from '../permissionWrapper';
import { ROLES_PERMISSIONS } from '../../constants';
import { IntegPartnerPoweredByLogo } from '../../assets/images';
import { openLinkInNewTab } from '../../helpers/generalHelpers';

function Sidebar() {
    const teamData = useSelector((state) => state.agenda?.userData?.lastSelectedTeam);
    const { sections } = useSelector((state) => state.agenda);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const checkIfLinkIsActive = (path) => {
        const activeHash = path.split('/')[2];
        if (location.hash === activeHash) {
            return 'active';
        }
        return '';
    };

    const navigateToIntegPartnerLink = () => {
        openLinkInNewTab(process.env.REACT_APP_POWERED_BY_LINK);
    };

    return (
        <>
            <div className="list-group sidebar">
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_READ]}>
                    <div className="agenda-box list-group-item list-group-item-action flex-column align-items-start border-0">
                        <div className="d-flex w-100 justify-content-between align-items-start">
                            <h4 className="mb-1">{teamData?.agendaTitle || t('agenda.agenda')}</h4>
                            <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_WRITE]}>
                                <i className="bi bi-gear cursor d-none d-md-block" onClick={() => dispatch(agendaModalToggle())}/>
                            </PermissionWrapper>
                        </div>
                        <small>{teamData?.agendaDescription}</small>
                        {sections?.map((section, sectionIndex) => (
                            <span className="mb-1 sidebar-list" key={sectionIndex}>
                                <h5 className="mb-1">{section?.title}</h5>
                                {section?.modules?.map((module, moduleIndex) => (
                                    <Button
                                        variant="link"
                                        className={checkIfLinkIsActive(`/module/#_${sectionIndex}_${moduleIndex}`)}
                                        key={moduleIndex}
                                        onClick={() => {
                                            navigate(`/module/#_${sectionIndex}_${moduleIndex}`);
                                            dispatch(setAgendaOpenedOnMobileScreen(false));
                                        }}
                                    >
                                        {module?.title}
                                    </Button>
                                ))}
                            </span>
                        ))}
                    </div>
                </PermissionWrapper>
                <div className="d-flex flex-column d-none d-sm-block text-center justify-content-around">
                    <p onClick={navigateToIntegPartnerLink} className="mb-0 cursor">Powered by</p>
                    <img
                        src={IntegPartnerPoweredByLogo}
                        alt="logo"
                        className="logo align-self-center cursor"
                        onClick={navigateToIntegPartnerLink}
                    />
                </div>
            </div>
            <PermissionWrapper allowed={[ROLES_PERMISSIONS.AGENDA_READ]}>
                <Agenda/>
            </PermissionWrapper>
        </>
    );
}

export default Sidebar;

