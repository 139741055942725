import { ToastContainer, Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_TYPE } from '../constants';

import { hideToast } from '../features/toastSlice';

function CustomToast() {
    const dispatch = useDispatch();
    const { isVisible, message, type } = useSelector((state) => state.toast);

    return (
        <ToastContainer className="p-3" position="bottom-start" style={{ zIndex: 999999 }}>
            <Toast bg={type} onClose={() => dispatch(hideToast())} show={isVisible} delay={3500} autohide>
                <Toast.Body className={`d-flex justify-content-between
                    ${type === TOAST_TYPE.ERROR || type.toLowerCase() === TOAST_TYPE.SUCCESS ? 'text-white' : 'text-black'} `}
                >
                    {message}
                    <i className="bi bi-x-lg cursor" onClick={() => dispatch(hideToast())}/>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default CustomToast;