import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MODULE_TYPES } from '../../../constants';
import Ideas from './ideas';
import Textbox from './textbox';
import Attendance from './attendance';
import ActionPlan from './actionPlan';
import GoalsAndMetrics from './goalsAndMetrics';
import Home from './dashboard';
import ExternalLinksAndDocuments from './externalLinksAndDocuments';

function ComponentLoader() {
    const location = useLocation();
    const path = location.hash.split('_');
    const module = useSelector((state) => state.agenda?.sections?.[path[1]]?.modules?.[path[2]]);
    const [activeModule, setActiveModule] = useState();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setActiveModule(module);
        setIsLoaded(true);
    }, [module]);

    const renderActiveComponent = () => {
        switch (activeModule?.type) {
            case MODULE_TYPES.IDEAS:
                return <Ideas module={activeModule}/>;
            case MODULE_TYPES.ACTIONS:
                return <ActionPlan module={activeModule}/>;
            case MODULE_TYPES.TEXT:
                return <Textbox module={activeModule}/>;
            case MODULE_TYPES.ATTENDANCE:
                return <Attendance module={activeModule}/>;
            case MODULE_TYPES.GOALS_AND_METRICS:
                return <GoalsAndMetrics module={activeModule}/>;
            case MODULE_TYPES.DOCUMENTS:
                return <ExternalLinksAndDocuments module={activeModule}/>;
            default:
                return <Home/>;
        }
    };

    return (
        isLoaded &&
            <div className="module-content">
                {renderActiveComponent()}
            </div>
    );
}

export default ComponentLoader;
