import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

function EditTeam({ closeModal, team, editTeam }) {
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error_message.required_field'))
    });
    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isDirty }
    } = useForm({ resolver: yupResolver(validationSchema) });

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('administration.edit_team')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(() => editTeam(isDirty, getValues()))} className="container d-flex flex-column">
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.teams_table.name')}</Form.Label>
                        <Form.Control
                            name="name"
                            {...register('name', { value: team?.name || '' })}
                            error={errors.name?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.name?.message && <Form.Label className="error-placeholder">{errors.name?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.teams_table.companyName')}</Form.Label>
                        <Form.Control
                            value={team?.companyName}
                            disabled
                            className="shadow-none border-color-primary"
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('administration.save_changes')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditTeam;