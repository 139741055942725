import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { REGEX, ROLES } from '../../../constants';

function EditUser({ closeModal, user, editUser, roles }) {
    const { t } = useTranslation();
    const loggedUser = useSelector(state => state.agenda?.userData);
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(t('error_message.required_field')),
        lastName: Yup.string().required(t('error_message.required_field')),
        phone: Yup.string().matches(REGEX.PHONE, { message: t('error_message.missing_correct_input'), excludeEmptyString: true }),
        description: Yup.string().notRequired(),
        role: Yup.string().notRequired()
    });
    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors, isDirty }
    } = useForm({ resolver: yupResolver(validationSchema) });

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('administration.edit_user')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(() => editUser(isDirty, getValues()))} className="container d-flex flex-column">
                    <Form.Group className="mb-3">
                        <Form.Label>{t('sign_up.first_name')}</Form.Label>
                        <Form.Control
                            name="firstName"
                            {...register('firstName', { value: user?.firstName || '' })}
                            error={errors.firstName?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.firstName?.message && <Form.Label className="error-placeholder">{errors.firstName?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('sign_up.last_name')}</Form.Label>
                        <Form.Control
                            name="lastName"
                            {...register('lastName', { value: user?.lastName || '' })}
                            error={errors.lastName?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.lastName?.message && <Form.Label className="error-placeholder">{errors.lastName?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('sign_up.email')}</Form.Label>
                        <Form.Control
                            value={user?.email}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t('sign_up.phone')}</Form.Label>
                        <Form.Control
                            name="phone"
                            {...register('phone', { value: user?.phone || '' })}
                            error={errors.phone?.message}
                            className="shadow-none border-color-primary"
                        />
                        {errors.phone?.message && <Form.Label className="error-placeholder">{errors.phone?.message}</Form.Label>}
                    </Form.Group>
                    {user?.registrationDate &&
                        <Form.Group className="mb-3">
                            <Form.Label>{t('administration.register_date')}</Form.Label>
                            <Form.Control
                                value={new Date(user?.registrationDate).toLocaleDateString('en-GB')}
                                disabled
                            />
                        </Form.Group>}
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-0">{t('role.role')}</Form.Label>
                        <Form.Select
                            className="form-control shadow-none border-color-primary"
                            placeholder="Role"
                            name="role"
                            {...register('role', { value: user?.role?._id })}
                            disabled={user?._id === loggedUser._id || user?.role?.name === ROLES.superAdmin.name}
                        >
                            {user?.role?.name === ROLES.superAdmin.name && (
                                <option value={user?.role?._id}>
                                    {t(`role.${ROLES[user?.role?.name].key}`)}
                                </option>
                            )}
                            {user?.role?.name !== ROLES.superAdmin.name && (
                                roles?.map((role) => (
                                    ROLES[role?.name].level <= ROLES[loggedUser?.role?.name].level &&
                                    role?.name !== ROLES.superAdmin.name &&
                                    (
                                        <option
                                            value={role?._id}
                                            key={role?._id}
                                        >
                                            {t(`role.${ROLES[role?.name].key}`)}
                                        </option>
                                    )))
                            )}
                        </Form.Select>
                        {errors?.role && <Form.Label className="error-placeholder">{errors.role?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Check
                        className="checkbox mb-3"
                        type="checkbox"
                        name="teamLead"
                        {...register('teamLead', { value: user?.teamsThatUserIsTeamLeadOf?.includes(loggedUser?.lastSelectedTeam?._id) })}
                        label={t('administration.team_lead')}
                    />
                    <Form.Group className="mb-3">
                        <Form.Label>{t('dashboard_shared.description')}</Form.Label>
                        <Form.Control
                            name="description"
                            {...register('description', { value: user?.description })}
                            className="shadow-none border-color-primary"
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button sign-button" style={{ width: '65%' }}>
                            {t('administration.save_changes')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditUser;