import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODULE_TYPES } from '../../../../../constants';
import { showToastErrorMessage } from '../../../../../features/toastSlice';
import { axios } from '../../../../../helpers/apiHelper';
import Textbox from './textbox';

function Textboxes() {
    const { sections } = useSelector((state) => state.agenda);
    const company = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?.company);
    const [textboxesInAgenda, setTextboxesInAgenda] = useState();
    const [textboxes, setTextboxes] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const fetchTextboxes = async () => {
        try {
            if (textboxesInAgenda && Object.keys(textboxesInAgenda)?.length > 0) {
                const textboxesResponse = await axios.get(
                    `/textbox/getAllTextboxesFromTeam?modules=${JSON.stringify(Object.keys(textboxesInAgenda))}`
                );
                setTextboxes(textboxesResponse?.data?.sort((a, b) => (
                    (textboxesInAgenda[a?.module?._id]?.sectionOrder > textboxesInAgenda[b?.module?._id]?.sectionOrder ||
                        a?.module?.order > b?.module?.order) ? 1 : -1)));
            } else {
                setTextboxes([]);
            }
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.dashboard.failed_loading_data')));
        }
    };

    useEffect(() => {
        if (sections) {
            const textboxesInAgendaTemp = {};
            sections?.map((section, sectionIndex) => section?.modules?.forEach((module, moduleIndex) => {
                if (module.type === MODULE_TYPES.TEXT) {
                    textboxesInAgendaTemp[module?._id] = { ...module, moduleIndex, sectionIndex, sectionOrder: section?.order };
                }
            }));
            setTextboxesInAgenda(textboxesInAgendaTemp);
        }
    }, [sections]);

    useEffect(() => {
        (async () => fetchTextboxes())();
    }, [textboxesInAgenda]);

    const hexToRGBA = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }
        return `rgb(${r}, ${g}, ${b})`;
    };

    useEffect(() => {
        if (company) {
            setBackgroundColor(hexToRGBA(company?.color, 0.5));
        }
    }, [company]);

    return (
        <Container className="horizontal-scrollable w-100 mb-1 justify-content-center">
            <Row className="row text-center w-100 mt-3 pb-1">
                {textboxes?.map((textbox) => (
                    <Col key={textbox?._id} xs={12} lg={textboxes?.length > 1 ? 6 : 12} className="column-scrollable mb-1 me-0 pe-0">
                        <Textbox
                            textbox={textbox}
                            backgroundColor={backgroundColor}
                            moduleIndex={textboxesInAgenda[textbox?.module?._id]?.moduleIndex}
                            sectionIndex={textboxesInAgenda[textbox?.module?._id]?.sectionIndex}
                        />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default Textboxes;