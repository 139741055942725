import axios from 'axios';

import { API_URL } from '../constants';

export const checkIfUserAuthorized = async () => {
    const token = localStorage.getItem('x-access-token');

    if (!token) {
        return false;
    }

    try {
        // dont use axios instance with interceptors
        await axios({
            method: 'post',
            url: `${API_URL}/user/verify`,
            headers: {
                'x-access-token': localStorage.getItem('x-access-token') || null
            }
        });
        return true;
    } catch (error) {
        return false;
    }
};