import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ROLES_PERMISSIONS } from '../../../../constants';
import { fetchBestContributions } from '../../../../features/actionsSlice';
import { showToastErrorMessage } from '../../../../features/toastSlice';
import { axios } from '../../../../helpers/apiHelper';
import PermissionWrapper from '../../../permissionWrapper';
import EditTask from '../actionPlan/editTask';
import GoalsAndMetricsGraph from './goalsAndMetricsGraph';
import MyActions from './myActions';
import Textboxes from './textboxes';

function Home() {
    const teamId = useSelector((state) => state.agenda?.userData?.lastSelectedTeam?._id);
    const {
        task: taskInEditMode,
        showEditTask
    } = useSelector((state) => state.actions);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const save = async (taskData) => {
        try {
            await axios.put(
                `task/update/${taskInEditMode?._id}`,
                { taskData }
            );
            await dispatch(fetchBestContributions({ teamId }));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    useEffect(() => {
        if (teamId) {
            (async () => {
                dispatch(fetchBestContributions({ teamId }));
            })();
        }
    }, [teamId]);

    return (
        <>
            <div className="dashboard-container m-2 w-100">
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.TEXTBOX_READ]}>
                    <Row className="w-100">
                        <Textboxes/>
                    </Row>
                </PermissionWrapper>
                <Row className="w-100">
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_READ, ROLES_PERMISSIONS.DOCUMENT_READ]}>
                        <Col xs={12} lg={6}>
                            <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_READ]}>
                                <Row className="w-100 ps-2"><MyActions/></Row>
                            </PermissionWrapper>
                        </Col>
                    </PermissionWrapper>
                    <PermissionWrapper allowed={[ROLES_PERMISSIONS.GOALS_AND_METRICS_READ]}>
                        <Col xs={12} lg={6} className="pe-5 chart-dashboard">
                            <GoalsAndMetricsGraph/>
                        </Col>
                    </PermissionWrapper>
                </Row>
            </div>
            {showEditTask &&
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_WRITE]}>
                    <EditTask save={save}/>
                </PermissionWrapper>}
        </>
    );
}

export default Home;
