import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { checkIfUserAuthorized } from './helpers/authHelper';
import DashboardLayout from './layouts/dashboardLayout';

export function NavigationOutlet() {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isTokenValidated, setIsTokenValidated] = useState(false);

    useEffect(() => {
        (async () => {
            if (await checkIfUserAuthorized()) {
                setIsAuthorized(true);
            }
            setIsTokenValidated(true);
        })();
    }, []);

    if (!isTokenValidated) {
        return null;
    }

    return isAuthorized ? <DashboardLayout/> : <Navigate to="/sign-in"/>;
}

