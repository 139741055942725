import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { cancelNewSection, saveNewSection } from '../../../features/agendaSlice';

function NewSection() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { newSection } = useSelector((state) => state.agenda);
    const [title, setTitle] = useState('');
    const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (!title && isSubmitButtonPressed) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [title, isSubmitButtonPressed]);

    const save = async () => {
        if (!isSubmitButtonPressed) {
            setIsSubmitButtonPressed(true);
        }
        if (title) {
            await dispatch(saveNewSection({ ...newSection, title }));
            dispatch(cancelNewSection());
        }
    };

    return (
        <div className="alert-secondary p-2 mt-4 align-items-center rounded d-flex justify-content-between section-title">
            <Form.Control
                className="agenda-section-title-edit border-color-primary shadow-none"
                defaultValue=""
                onChange={(event) => setTitle(event.target.value)}
                isInvalid={showError && !title}
                placeholder={t('content.title')}
            />
            <div>
                <i className="bi bi-check-square ms-2 visible-icon" onClick={save}/>
                <i className="bi bi-x-square ms-2 visible-icon" onClick={() => dispatch(cancelNewSection())}/>
            </div>
        </div>
    );
}

export default NewSection;
