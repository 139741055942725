import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import annotationPlugin from 'chartjs-plugin-annotation';
import i18n from '../../../../i18n';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    annotationPlugin
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        tooltip: {
            callbacks: {
                label: (context) => [
                    context.raw.label,
                    `${i18n.t('content.value')}: ${context.raw.value}`,
                    `${i18n.t('content.percentage')}:  ${context.raw.percentageValue ?
                        context.raw.percentageValue.toFixed(3) :
                        context.raw.percentageValue}%`,
                    `${i18n.t('content.end_goal')}: ${context.raw.endGoal}`
                ]
            }
        }

    }
};

const axisLabel = (label) => ({
    title: {
        display: true,
        text: label,
        align: 'end'
    }
});

function Chart({ data, additionalOptionsProperties, percentage, maxYAxis }) {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState(data);
    const [percentageState, setPercentageState] = useState(percentage);
    const [additionalOptionsState, setAdditionalOptionsState] = useState(additionalOptionsProperties || {});
    const [maxYAxisState, setMaxYAxisState] = useState(maxYAxis);
    useEffect(() => {
        setChartData(data);
    }, [data]);

    useEffect(() => {
        setPercentageState(percentage);
    }, [percentage]);

    useEffect(() => {
        setAdditionalOptionsState(additionalOptionsProperties);
    }, [additionalOptionsProperties]);

    useEffect(() => {
        setMaxYAxisState(maxYAxis);
    }, [maxYAxis]);

    const optionsWithAnnotations = {
        ...options,
        plugins: { ...options.plugins,
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: maxYAxis,
                        yMax: maxYAxis,
                        borderColor: 'red',
                        borderWidth: 2
                    }
                }
            } }
    };

    return <Bar
        options={{
            ...{
                ...optionsWithAnnotations,
                scales: {
                    ...options?.scales,
                    y: { ...axisLabel(percentageState ?
                        `${t('content.percentage').toLowerCase()} [%]` :
                        `${t('content.value').toLowerCase()} [${t('content.number')}]`),
                    suggestedMax: maxYAxisState
                    } }
            },
            ...additionalOptionsState
        }}
        data={chartData}
    />;
}

export default Chart;